import { flow, getRoot, Instance, types } from 'mobx-state-tree'

import API from 'src/common/api'
import { ICreateSubscriptionOptions } from 'src/common/api/sections/subscriptions/types'
import { stripe } from 'src/common/components/stripe'
import { IStore } from '../index'
import { preFillSubscriptionOptions, setPlanOptions } from './utils'
import {sendEvent} from 'src/common/utils/analytics'
import {EVENTS_AMPLITUDE} from 'src/common/constants/analytics/events'
import {AMPLITUDE} from 'src/common/constants/analytics'
import { ISubPlan } from 'src/store/models/sub-plan'
import { PAYMENT_INTENT_STATUSES } from 'src/common/constants/subscription/payment'
import { getErrorMessage } from 'src/common/utils/errors'

export const Subscription = types
  .model('Subscription')
  .views(self => ({
    get rootStore(): IStore {
      return getRoot(self)
    }
  }))
  .actions((self) => ({
    purchase: flow(function* (options: ICreateSubscriptionOptions, plan: ISubPlan) {
      try {
        self.rootStore.base.setError('')

        const { authentication: { email } } = self.rootStore
        const opt = preFillSubscriptionOptions(options, email)
        const concretePlan = setPlanOptions(plan)

        const { paymentMethod, error } = yield stripe.createPaymentMethod(opt)
        if (error) return Object.assign(error, {status: PAYMENT_INTENT_STATUSES.error})

        try {
          const { clientSecret, paymentIntentStatus } = yield API.subscription.createSubscription(paymentMethod.id, concretePlan)
          if (paymentIntentStatus === PAYMENT_INTENT_STATUSES.requiresAction) {
            const result = yield stripe.confirmCardPayment(clientSecret, {payment_method: opt})

            if (result.error) return Object.assign(result.error, {status: PAYMENT_INTENT_STATUSES.error})
            return result.paymentIntent
          }

          sendEvent(EVENTS_AMPLITUDE.StripeShow, [AMPLITUDE])

          return Object.assign({}, paymentMethod, {status: PAYMENT_INTENT_STATUSES.succeeded})
        } catch (subscriptionError) {
          self.rootStore.base.setError(getErrorMessage(subscriptionError))
          return Object.assign({}, paymentMethod, {status: PAYMENT_INTENT_STATUSES.error})
        }
      } catch (e) {
        self.rootStore.base.setError(getErrorMessage(e))
        throw e
      }
    }),
  }))

export interface ISubscription extends Instance<typeof Subscription> {}
