const font = {
  family: 'CoFo Sans, sans-serif',
  weight: {
    bold: 700,
    medium: 500,
    regular: 300,
  },
}

const typography: { [index: string]: any } = {
  fontFamily: font.family,
  fontWeight: font.weight,
  title1: {
    fontSize: '34px',
    fontWeight: font.weight.bold,
    lineHeight: '36px',
    letterSpacing: '-0.1px',
  },
  title2: {
    fontSize: '28px',
    fontWeight: font.weight.bold,
    lineHeight: '32px',
    letterSpacing: '0px',
  },
  title3: {
    fontSize: '20px',
    fontWeight: font.weight.bold,
    lineHeight: '24px',
    letterSpacing: '0px',
  },
  headline: {
    fontSize: '16px',
    fontWeight: font.weight.bold,
    lineHeight: '20px',
    letterSpacing: '0.1px',
  },
  bodyMedium: {
    fontSize: '16px',
    fontWeight: font.weight.medium,
    lineHeight: '20px',
    letterSpacing: '0.1px',
  },
  bodyRegular: {
    fontSize: '16px',
    fontWeight: font.weight.regular,
    lineHeight: '20px',
    letterSpacing: '0.1px',
  },
  subheadlineMedium: {
    fontSize: '14px',
    fontWeight: font.weight.medium,
    lineHeight: '16px',
    letterSpacing: '0.1px',
  },
  subheadlineRegular: {
    fontSize: '14px',
    fontWeight: font.weight.regular,
    lineHeight: '16px',
    letterSpacing: '0.1px',
  },
  captionMedium: {
    fontSize: '11px',
    fontWeight: font.weight.medium,
    lineHeight: '14px',
    letterSpacing: '0.1px',
  },
  captionRegular: {
    fontSize: '11px',
    fontWeight: font.weight.regular,
    lineHeight: '14px',
    letterSpacing: '0.1px',
  },
  objToCss: (unit: string) => {
    const textTransform = typography[unit].textTransform
      ? `text-transform: ${typography[unit].textTransform};`
      : ''

    return `
      font-size: ${typography[unit].fontSize};
      font-weight: ${typography[unit].fontWeight};
      line-height: ${typography[unit].lineHeight};
      letter-spacing: ${typography[unit].letterSpacing};
      ${textTransform}
    `
  },
}

const shadows = {
  surface: '0 1px 6px rgba(25, 31, 37, 0.06)',
  layer: '0 1px 5px rgba(25, 31, 37, 0.12)',
  float: '0 6px 24px rgba(22, 29, 36, 0.12)',
}

const staticColors = {
  black: {
    100: '#000000',
    90: 'rgba(0, 0, 0, 0.9)',
    70: 'rgba(0, 0, 0, 0.7)',
    40: 'rgba(0, 0, 0, 0.4)',
    20: 'rgba(0, 0, 0, 0.2)',
    10: 'rgba(0, 0, 0, 0.1)',
  },
  white: {
    100: '#FFFFFF',
    90: 'rgba(255, 255, 255, 0.9)',
    70: 'rgba(255, 255, 255, 0.7)',
    40: 'rgba(255, 255, 255, 0.4)',
    20: 'rgba(255, 255, 255, 0.2)',
    10: 'rgba(255, 255, 255, 0.1)',
  },
}

export const lightTheme = {
  typography,
  shadows,

  palette: {
    ...staticColors,

    label: {
      primary: '#1C1C1E',
      secondary: 'rgba(63, 63, 69, 0.72)',
      tertiary: 'rgba(62, 62, 67, 0.3)',
      onAccent: 'rgba(0, 0, 0, 0.9)',
    },

    background: {
      base: '#FFFFFF',
      cellar: '#F7F7F7',
      surface: '#FFFFFF',
      elevated: '#FFFFFF',
    },

    fill: {
      primary: 'rgba(122, 122, 128, 0.2)',
      secondary: 'rgba(122, 122, 128, 0.16)',
      tertiary: 'rgba(120, 120, 128, 0.12)',
      quaternary: 'rgba(118, 118, 128, 0.08)',
    },

    overlay: {
      base: 'rgba(0, 0, 0, 0.4)',
    },

    accent: {
      base: '#9EFF22',
    },

    highlight: {
      base: '#0F6AFF',
      trail: 'rgba(15, 106, 255, 0.3)',
    },

    error: {
      base: '#FF5B5B',
      trail: 'rgba(255, 91, 91, 0.3)',
    },
  },
}

export type Theme = typeof lightTheme

export default lightTheme
