import React from 'react'

import GoogleLogin from 'react-google-login'

import { GOOGLE_WEB_CLIENT_ID } from 'src/common/constants/authentication'
import { ButtonSize, ButtonView } from 'src/common/components/button'
import googleIcon from 'assets/icons/google.svg'

import { Button } from './styles'

export interface IButtonGoogleLoginProps {
  id?: string
  className?: string
  loading?: boolean
  onClick?: () => void
  onSubmit: (data: any) => void
  colorTheme?: string
}

export const ButtonGoogleLogin: React.FC<IButtonGoogleLoginProps> = ({
  id,
  className,
  onSubmit,
  loading,
  onClick,
  colorTheme
}: IButtonGoogleLoginProps) => {
  return (
    <GoogleLogin
      clientId={GOOGLE_WEB_CLIENT_ID}
      render={({ onClick: click, disabled }) => (
        <Button
          id={id}
          className={className}
          view={ButtonView.Secondary}
          size={ButtonSize.Large}
          disabled={disabled}
          startIcon={googleIcon}
          onClick={() => {
            if (onClick) {
              onClick()
            }
            click()
          }}
          isLoading={loading}
          fullWidth
          colorTheme={colorTheme}
        >
          Sign In with Google
        </Button>
      )}
      onSuccess={onSubmit}
      accessType="accessType"
      responseType="code"
    />
  )
}

export default ButtonGoogleLogin
