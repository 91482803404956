import { createGlobalStyle } from 'styled-components'

const GlobalStyles = createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  html {
    font-family: CoFo Sans, sans-serif;
  }

  @supports (font-variation-settings: normal) {
    html { font-family: CoFo Sans, sans-serif; }
  }
`

export default GlobalStyles
