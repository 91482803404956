import React from 'react'

import { IStoresMap } from 'src/store/mst'
import connector from 'src/common/decorators/connector'
import { Input } from 'src/common/components/input'
import { Button2, ButtonSize, ButtonView } from 'src/common/components/button'
import { ComponentSize } from 'src/common/constants/web-kit/sizes'
import { FlowStep } from 'src/common/constants/flow'
import { validateEmail } from 'src/common/utils/email'
import mailIcon from 'assets/icons/mail.svg'
import { LandingContextConsumer } from 'src/common/decorators/landingContext'
import { InputWrapper } from '../styles'

export const EMAIL_ERRORS = {
  EmptyEmail: 'Enter an email address',
  IncorrectEmail: 'Incorrect email address',
}

export type EmailFormProps = ReturnType<typeof storesToProps>

export interface IEmailFormState {
  email: string
  loading: boolean
}

class EmailForm extends React.Component<EmailFormProps, IEmailFormState> {
  state = {
    email: '',
    loading: false,
  }

  public handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ email: e.target.value })
  }

  public isEmailValid = (email: string): string => {
    if (email.length === 0) {
      return EMAIL_ERRORS.EmptyEmail
    }
    return validateEmail(email) ? '' : EMAIL_ERRORS.IncorrectEmail
  }

  public handleSubmit = async (e: React.FormEvent<HTMLFormElement>, landing: string) => {
    e?.preventDefault()

    const { authentication, flow } = this.props

    authentication.setError('')

    // Validation
    const { email } = this.state
    const error = this.isEmailValid(email)
    if (error) {
      authentication.setError(error)
      return
    }

    // Authorization
    try {
      this.setState({ loading: true })
      await authentication.authByEmail(email.toLowerCase())
      flow.setStep(FlowStep.confirmation)
    } catch (e) {
      throw e
    }
  }

  public render() {
    const { authentication: { error } } = this.props
    const { email, loading } = this.state

    return (
      <LandingContextConsumer>
        {(landing) => (
          <form onSubmit={(event) => {this.handleSubmit(event, landing)}}>
            <InputWrapper>
              <Input
                type={'email'}
                placeholder="Your email"
                value={email}
                onChange={this.handleEmailChange}
                startIcon={mailIcon}
                error={error}
                componentSize={ComponentSize.Large}
                showError
                fullWidth
              />
            </InputWrapper>

            <Button2
              id="prisma_checkout-flow_sign_btn-continue"
              view={ButtonView.CTA}
              size={ButtonSize.Large}
              isLoading={loading}
              fullWidth
            >
              Continue
            </Button2>
          </form>
        )}
      </LandingContextConsumer>
    )
  }
}

const storesToProps = ({ store }: IStoresMap, _nextProps: any) => ({
  authentication: store.authentication,
  flow: store.flow
})

export default connector(storesToProps)(EmailForm)
