import styled, { keyframes } from 'src/common/styles'

import { LoaderProps } from './types'

const spin = keyframes`
  100% { 
    transform: rotate(360deg); 
  }
`

export const StyledLoader = styled.div<LoaderProps>`
  width: ${(props) => props.size ?? 24}px;
  height: ${(props) => props.size ?? 24}px;

  border-radius: 50%;
  border: 3px solid ${(props) => props.theme.palette.label.primary};
  border-bottom-color: transparent;

  animation: ${spin} 0.7s linear infinite;
`
