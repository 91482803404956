import React from 'react'

import { RouteComponentProps, withRouter } from 'react-router-dom'
import MobileDetect from 'mobile-detect'
import { IStoresMap } from 'src/store/mst'
import connector from 'src/common/decorators/connector'
import GoogleAuth from './auth-options/google-auth'
import AppleAuth from './auth-options/apple-auth'
import RegistrationBlock from './registration-block'
import ConfirmationBlock from './confimation-block'
import { FlowStep } from '../../common/constants/flow'
import { TERMS_OF_USE_LINK, WEB_PRIVACY_LINK } from 'src/common/constants/privacy'
import { sendEvent } from 'src/common/utils/analytics'
import { EVENTS_AMPLITUDE } from 'src/common/constants/analytics/events'
import { AMPLITUDE } from 'src/common/constants/analytics'
import closeSvg from 'assets/icons/close_small.svg'
import {
  TransparentButtonView,
  Content,
  Header,
  Title,
  Link,
  PrivacyTerms,
  Wrapper,
  HiddenContent,
  HiddenHeader,
  HiddenBody,
  HeaderTitle,
  CloseButton,
  CloseIcon
} from './styles'

interface IAuthState {
  hiddenContentOpen: boolean
}

interface IAuthProps {
  landing: string
}

type AuthPropsType = IAuthProps & RouteComponentProps & ReturnType<typeof storesToProps>

class Auth extends React.Component<AuthPropsType, IAuthState> {
  public state = { hiddenContentOpen: false };

  public componentDidMount() {
    this.sendAnalytic()
    const { flow } = this.props
    flow.setStep(FlowStep.registration)
  }

  public sendAnalytic() {
    const params = new URLSearchParams(this.props.location.search)
    const mediaSource = params.get('media_source')
    const campaign = params.get('campaign')

    sendEvent(EVENTS_AMPLITUDE.ActionShow, [AMPLITUDE], {
      context: 'sign_in',
      media_source: mediaSource,
      campaign
    })
  }

  public get md() {
    return new MobileDetect(window.navigator.userAgent)
  }

  public render() {
    const isIOS = this.md.os() === 'iOS'
    const { flow } = this.props

    return (
      <Wrapper>
        <Content>
          <Header>
            <Title>Become an artist</Title>
            <Title>with our AI</Title>
          </Header>

          {isIOS ?
            <AppleAuth colorTheme={'dark'} location={this.props.location} /> :
            <GoogleAuth colorTheme={'dark'} location={this.props.location} />
          }

          <TransparentButtonView onClick={() => this.setState({hiddenContentOpen: true})}>
            Sign In with Other Methods
          </TransparentButtonView>

          <PrivacyTerms>
            By signing up you are agreeing to our <br/>
            <Link target="blank" href={TERMS_OF_USE_LINK}>Terms of Use</Link> &amp;{' '}
            <Link target="blank" href={WEB_PRIVACY_LINK}>Privacy Policy.</Link>
          </PrivacyTerms>
        </Content>

        <HiddenContent isOpen={this.state.hiddenContentOpen}>
          <HiddenHeader>
            <CloseButton onClick={() => this.setState({hiddenContentOpen: false})}>
              <CloseIcon icon={closeSvg} width={10} square />
            </CloseButton>
            <HeaderTitle>
              Sign In with Email
            </HeaderTitle>
          </HiddenHeader>

          <HiddenBody>
            {flow.step === FlowStep.registration ?
              <RegistrationBlock location={this.props.location}/> : <ConfirmationBlock location={this.props.location}/>
            }
          </HiddenBody>
        </HiddenContent>
      </Wrapper>
    )
  }
}

const storesToProps = ({ store }: IStoresMap, _nextProps: any) => ({
  flow: store.flow
})

export default withRouter(connector(storesToProps)(Auth))