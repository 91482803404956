import React from 'react'

import { RouteComponentProps, withRouter } from 'react-router-dom'

import { IStoresMap } from 'src/store/mst'
import connector from 'src/common/decorators/connector'
import { sendEvent } from 'src/common/utils/analytics'
import { EVENTS_AMPLITUDE } from 'src/common/constants/analytics/events'
import { AMPLITUDE } from 'src/common/constants/analytics'
import { ButtonAppleLogin } from 'src/common/components/button-apple-login'
import { getURLParamsAfterAppleAuth } from 'src/common/utils/authentication'

import appleSvg from 'assets/icons/apple.svg'
import whiteAppleSvg from 'assets/icons/apple_white.svg'

import { ButtonView } from '../styles'
import { LandingContextConsumer } from 'src/common/decorators/landingContext'

interface IAppleAuthProps {
  colorTheme?: string,
  location?: {
    search: string
  }
}

type AppleAuthPropsType = IAppleAuthProps & RouteComponentProps & ReturnType<typeof storesToProps>

class AppleAuth extends React.Component<AppleAuthPropsType> {
  state = {
    loading: false
  }

  public handleAppleAuth = async () => {
    const params = getURLParamsAfterAppleAuth(this.props.location.search)
    if (!params) {
      return
    }

    const { authentication } = this.props
    await authentication.authByApple(params)
  }

  public handleClick = () => {
    this.setState({loading: true})
    this.sendAnalytic()
  }

  public sendAnalytic = () => {
    const params = new URLSearchParams(this.props.location.search)

    sendEvent(EVENTS_AMPLITUDE.ActionButtonTap, [AMPLITUDE], {
      context: 'sign_in',
      media_source: params.get('media_source'),
      campaign: params.get('campaign')
    })
  }

  public render() {
    const { colorTheme } = this.props

    return (
      <LandingContextConsumer>
        {(landing) => (
          <ButtonAppleLogin
            redirectUrl={landing + '/offer'}
            onClick={this.handleClick}
          >
            <ButtonView
              colorTheme={colorTheme}
              startIcon={colorTheme === 'light' ? whiteAppleSvg : appleSvg}
            >
              Sign In with Apple
            </ButtonView>
          </ButtonAppleLogin>
        )}
      </LandingContextConsumer>
    )
  }
}

const storesToProps = ({ store }: IStoresMap, _nextProps: any) => ({
  authentication: store.authentication
})

export default withRouter(connector(storesToProps)(AppleAuth))
