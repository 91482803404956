import styled from 'src/common/styles'

import { Button2, ButtonSize } from 'src/common/components/button'
import { Typography, TypographyView } from 'src/common/components/typography'

export const ImageWrapper = styled.div`
  position: absolute;
  width: 100%;
  left: 50%;
  top: 50%;
  overflow: hidden;
  border-radius: 12px;
  filter: drop-shadow(0px 7px 30px rgba(0, 0, 0, 0.36));
  animation-duration: 4s;
  animation-timing-function: cubic-bezier(.82,.35,.24,.68);
  animation-iteration-count: infinite;

  &:before {
    content: "";
    display: block;
    width: 100%;
    padding-top: 133.5%;
  }

  &:first-child {
    transform: translate(-50%, -50%) rotate(-8deg);
    margin-left: 0;
    z-index: 1;
    animation-direction: alternate;
  }

  &:nth-child(2) {
    transform: translate(-50%, -58%) rotate(8deg) scale(0.72);
    margin-left: 33%;
    opacity: 0.36;
    z-index: 0;
    animation-direction: alternate-reverse;
  }

  @keyframes originalImage {
    0%, 43% {
      transform: translate(-50%, -50%) rotate(-8deg);
      margin-left: 0;
      opacity: 1;
      z-index: 1;
    }
    
    49% {
      z-index: 1;
    }
    
    51% {
      z-index: 0;
    }
    
    57%, 100% {
      transform: translate(-50%, -58%) rotate(8deg) scale(0.72);
      margin-left: 33%;
      opacity: 0.36;
      z-index: 0;
    }
  }
`

export const Wrapper = styled.div`
  position: relative;
  display: block;
  height: 100vh;
  max-height: -webkit-fill-available;
  background: #000000;

  .carousel {
    .control-dots {
      margin: 12px 0;
      line-height: 0;
      
      .dot {
        width: 6px;
        height: 6px;
        margin: 0 3px;

        &:last-child {
          pointer-events: none;
        }
      }
    }
  }

  .slide {
    &.selected,
    &.previous {
      ${ImageWrapper} {
        animation-name: originalImage;
      }
    }
  }
`

export const Content = styled.div<{color: string}>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 100vh;
  max-height: 675px;
  max-height: -webkit-fill-available;
  overflow: hidden;
  margin: auto 0;
  ${({ color }) => `background: linear-gradient(180deg, ${color} 0%, #000000 100%);`}
`

export const Images = styled.div`
  position: relative;
  margin: auto 0 auto -4px;
  width: 72%;
`;

export const Image = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

export const BottomSheetWrapper = styled.div`
  padding: 0 24px;
`

export const Title = styled(Typography).attrs({
  view: TypographyView.Title1,
})`
  display: inline-block;
  width: 100%;
  margin-bottom: 16px;
  color: #FFFFFF;
  text-align: left;
`

export const Button = styled(Button2).attrs({
  size: ButtonSize.Large,
  fullWidth: true,
})<{bg?: string}>`
  background-color: ${({ bg }) => bg ? bg : '#FFFFFF' };
  color: rgba(0, 0, 0, 0.9);
  ${(props) => props.theme.typography.objToCss(TypographyView.Headline)}
`

export const Inner = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  height: 100%;
  width: 100%;
  max-height: 650px;
  max-height: -webkit-fill-available;
  max-width: 375px;
  padding: 0 0 30px;
`
