import styled from 'src/common/styles'

import {BaseButton} from 'src/common/components/button/styles'
import { Typography, TypographyView } from 'src/common/components/typography'

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  max-width: 375px;
  margin: 0 auto;
  padding: 32px 24px;
  
  > * {
    flex-shrink: 0;
  }
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
`

export const Title = styled(Typography).attrs({
  view: TypographyView.Headline
})`
  width: 50%;
  margin-bottom: 16px;
  color: #000000;
  text-align: left;
`

export const StripeTitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`

export const StripeSecure = styled.div`
  margin-bottom: 12px;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  color: #636366;
`

export const StripePowered = styled.div`
  svg {
    display: block;
    height: 21px;
    width: auto;
  }
`

export const Button = styled(BaseButton)<{bg?: string}>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 48px;
  font-weight: bold;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.9);
  background-color: ${({ bg }) => bg ? bg : '#FFFFFF' };
  border-radius: 10px;
`

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: auto;
`

export const PrismaDates = styled(Typography).attrs({
  view: TypographyView.SubHeadlineRegular
})`
  margin-top: 32px;
  color: rgba(63, 63, 69, 0.72);
  text-align: center;
`

export const Links = styled(Typography).attrs({
  view: TypographyView.SubHeadlineRegular
})`
  display: flex;
  justify-content: center;
  margin-top: 8px;
`

export const Link = styled.a`
  position: relative;
  display: block;
  color: rgba(63, 63, 69, 0.72);
  text-align: center;
  text-decoration: none;
  border-bottom: 1px solid rgba(62, 62, 67, 0.3);
  white-space: nowrap;
  
  &:not(:last-child) {
    margin-right: 16px;
    
    &:after {
      content: "•";
      position: absolute;
      left: 100%;
      margin-left: 6px;
    }
  }
`

export const Stores = styled.div`
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const StoreButtons = styled.div`
  display: flex;
  flex-wrap: nowrap;
`

export const StoreButton = styled.a`
  display: block;
  max-width: calc(50% - 8px);
  
  &:not(:first-child) {
    margin-left: 16px;
  }
  
  svg {
    display: block;
    max-height: 48px;
    max-width: 100%;
    width: auto;
    height: auto;
  }
`

export const Stats = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 20px;
`

export const StatsItem = styled(Typography).attrs({
  view: TypographyView.Headline
})`
  width: 50%;
  padding: 6px 0;
  font-weight: 300;
  color: #000000;
  
  > * {
    white-space: nowrap;
  }
  
  b {
    font-weight: bold;
  }
  
  &:nth-child(odd) {
    text-align: right;
    padding-right: 8px;
  }

  &:nth-child(even) {
    border-left: 1px solid #C4C4C4;
    padding-left: 8px;
  }
`

export const Info = styled.p`
  max-width: 325px;
  margin: 44px auto 0;
  font-weight: 300;
  font-size: 11px;
  line-height: 14px;
  letter-spacing: 0.1px;
  text-align: center;
  color: rgba(63, 63, 69, 0.72);

  b {
    font-weight: 500;
  }
`

export const Points = styled.div`
  max-width: 325px;
  margin: 40px auto 0;
  padding: 0 8px;
`

export const Point = styled(Typography).attrs({
  view: TypographyView.BodyMedium
})`
  position: relative;
  padding-left: 36px;
  color: #000000;
  
  &:not(:last-child) {
    margin-bottom: 20px;
  }
  
  svg {
    position: absolute;
    left: 0;
    top: 0;
    width: 20px;
    height: 20px;
    border-radius: 100%;
    background-color: #FFD700;
  }
`
