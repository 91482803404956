import styled from 'src/common/styles'
import { Button2 as ButtonBase } from 'src/common/components/button'

export const Button = styled(ButtonBase)<{ colorTheme?: string }>`
  ${(props) => {
    if (props.colorTheme === 'light') {
      return `
        background-color: rgba(118, 118, 128, 0.08);
      `
    }
    if (props.colorTheme === 'dark') {
      return `
        &:active:not(:disabled) {
          color: #FFFFFF;
        }
      `
    }
  }}
`
