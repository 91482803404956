import SectionBase from 'src/common/api/sections/base'

export default class User extends SectionBase {
  public sendEmailAllowance = (allowed: boolean) => {
    return this.service.request({
      url: '/auth/user/sent_email_allowance',
      method: 'POST',
      data: {
        app: 'prisma',
        value: allowed,
      },
    })
  }
}
