import React from 'react'

import Svg from 'src/common/components/svg'

import { Button2Props, ButtonSize } from './types'
import { ButtonText, Loader, StyledButton } from './styles'

export const Button2: React.FC<Button2Props> = ({
  id,
  className,
  startIcon,
  endIcon,
  children,
  onClick,
  view,
  size,
  fullWidth,
  disabled,
  isLoading,
}: Button2Props) => {
  return (
    <StyledButton
      id={id}
      className={className}
      view={view}
      size={size ?? ButtonSize.Medium}
      fullWidth={fullWidth}
      onClick={onClick}
      disabled={disabled}
      isLoading={isLoading}
    >
      {startIcon && !isLoading && (
        <Svg className="icon-start" icon={startIcon} />
      )}
      <ButtonText isLoading={isLoading}>{children}</ButtonText>
      {endIcon && !isLoading && <Svg className="icon-end" icon={endIcon} />}
      {isLoading && <Loader className="loader" />}
    </StyledButton>
  )
}

export default Button2
