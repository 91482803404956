import styled from 'src/common/styles'
import { Typography, TypographyView } from 'src/common/components/typography'

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  -webkit-tap-highlight-color: transparent;

  & > svg {
    margin-right: 10px;
  }
`

export const Text = styled(Typography).attrs({
  view: TypographyView.BodyMedium
})`
  margin-top: 2px;
  color: #000000;
`
