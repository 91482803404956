import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { IStoresMap } from 'src/store/mst'
import connector from 'src/common/decorators/connector'
import { Elements } from '@stripe/react-stripe-js';
import { stripe } from 'src/common/components/stripe'
import { TERMS_OF_USE_LINK, WEB_PRIVACY_LINK, SUPPORT_LINK } from 'src/common/constants/privacy';
import { STORE_LINKS } from 'src/common/constants/links';
import { EVENTS_AMPLITUDE } from 'src/common/constants/analytics/events'

import { Platform } from 'src/common/constants/platforms';
import { InjectedCheckoutForm } from './components/card-form';
import {
  Wrapper,
  Header,
  Title,
  StripeTitle,
  StripeSecure,
  StripePowered,

  Stores,
  StoreButtons,
  StoreButton,
  Stats,
  StatsItem,
  Info,
  Points,
  Point,
  Footer,
  PrismaDates,
  Links,
  Link
} from './styles'
import history from "../../router/history";
import {sendEvent} from "../../common/utils/analytics";
import {AMPLITUDE} from "../../common/constants/analytics";
import Svg from 'src/common/components/svg'
import check from 'assets/icons/check.svg'
import stripeIcon from 'assets/images/first-landing/stripe.svg'
import iosLogo from '/assets/images/stores/app_store.svg'
import androidLogo from '/assets/images/stores/google_play.svg'

export type PaymentProps = ReturnType<typeof storesToProps> & RouteComponentProps

class Payment extends React.Component<PaymentProps> {
  constructor(props: PaymentProps) {
    super(props)
    const { authentication } = this.props

    if (!authentication.email) {
      history.push({
        pathname: '/first/auth',
        search: this.props.location.search
      })
    }
  }

  public sendAnalytic = (appStore: string) => {
    const params = new URLSearchParams(this.props.location.search)

    sendEvent(EVENTS_AMPLITUDE.InstallButtonTap, [AMPLITUDE], {
      app_store: appStore,
      media_source: params.get('media_source'),
      campaign: params.get('campaign')
    })
  }

  public render() {
    const forwind3Days = new Date(new Date().getTime()+(3*24*60*60*1000))
      .toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric'})

    return (
      <Wrapper>
        <Header>
          <Title>Payment Information</Title>
          <StripeTitle>
            <StripeSecure>Secure Server</StripeSecure>
            <StripePowered>
              <Svg icon={stripeIcon} />
            </StripePowered>
          </StripeTitle>
        </Header>

        <Elements stripe={stripe}>
          <InjectedCheckoutForm />
        </Elements>

        <Stores>
          <StoreButtons>
            <StoreButton onClick={() => {this.sendAnalytic('apple')}} href={STORE_LINKS[Platform.ios]}>
              <Svg icon={iosLogo}/>
            </StoreButton>
            <StoreButton onClick={() => {this.sendAnalytic('google')}} href={STORE_LINKS[Platform.android]}>
              <Svg icon={androidLogo}/>
            </StoreButton>
          </StoreButtons>
          <Stats>
            <StatsItem><b>120m+</b> downloads</StatsItem>
            <StatsItem><b>200k+</b> <span>5-star ratings</span></StatsItem>
          </Stats>
        </Stores>

        <Points>
          <Point>
            <Svg icon={check}/>Cancel any time
          </Point>
          <Point>
            <Svg icon={check}/>3-day trial for free
          </Point>
          <Point>
            <Svg icon={check}/>An email receipt will be sent to you each time your subscription renews
          </Point>
        </Points>

        <Footer>
          <Info>
            Your 3-day trial will expire on {forwind3Days}. The&nbsp;trial period&nbsp;can be canceled anytime
            before the expiration day and you will not be charged the full plan&nbsp;amount. If you don’t cancel your
            subscription before the end of your trial period, you’ll automatically be&nbsp;charged
            the <b>$29.99 subscription</b> fee for&nbsp;<b>Prisma&nbsp;Premium</b> every month until you cancel it.
            Prisma Premium subscription can be canceled anytime.
          </Info>

          <PrismaDates>
            © 2016 - {new Date().getFullYear()} Prisma Labs, Inc.
          </PrismaDates>
          <Links>
            <Link target="blank" href={SUPPORT_LINK}>Contact Support</Link>
            <Link target="blank" href={WEB_PRIVACY_LINK}>Privacy Policy</Link>
            <Link target="blank" href={TERMS_OF_USE_LINK}>Term of Use</Link>
          </Links>
        </Footer>
      </Wrapper>
    )
  }
}

const storesToProps = ({ store }: IStoresMap, _nextProps: any) => ({
  authentication: store.authentication,
  flow: store.flow
})

export default withRouter(connector(storesToProps)(Payment))
