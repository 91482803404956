import styled from 'src/common/styles'

import { ButtonView } from '../auth/styles'
import { Typography, TypographyView } from 'src/common/components/typography'

export const Content = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  height: 100%;
  width: 100%;
  max-height: 675px;
  max-width: 375px;
  margin: auto;
  padding: 0 32px 32px;
  z-index: 1;
`

export const Subtitle = styled(Typography).attrs({
  view: TypographyView.BodyRegular
})`
  margin-top: 8px;
  text-align: center;
  color: rgba(255, 255, 255, 0.7);
`

export const Price = styled(Typography).attrs({
  view: TypographyView.Title3
})`
  margin-top: 24px;
  font-weight: 300;
  text-align: center;
  color: #FFFFFF;
  
  span {
    display: block;
    font-weight: bold;
    color: #FFEE58;
  }
`;

export const Button = styled(ButtonView)`
  margin: 22px 0 0;
  font-weight: bold;
`