import SectionBase from 'src/common/api/sections/base'

import {
  IAuthEmailRequest,
  IAuthGoogleRequest,
  IAuthGoogleResult,
  IConfirmRequest,
  IHiddenAuthRequest,
  IHiddenAuthResult
} from './types'

export default class Auth extends SectionBase {
  public authByEmail = (data: IAuthEmailRequest): Promise<string> =>
    this.service
      .request({
        url: '/auth/email',
        method: 'POST',
        data: {
          ...data,
        },
      })
      .then(({ user_id }) => user_id)

  public confirmByEmail = (data: IConfirmRequest) =>
    this.service
      .request({
        url: '/auth/confirm/email',
        method: 'POST',
        data: {
          ...data,
          app: 'prisma',
          platform: 'web',
        },
      })
      .then(({ token }) => token)
      .catch(error => {
        if (error?.response?.status === 500) {
          throw new Error('Invalid code, please enter one from email')
        }
        throw error
      })

  public authByGoogle = (data: IAuthGoogleRequest): Promise<IAuthGoogleResult> =>
    this.service
      .request({
        url: '/auth/google',
        method: 'POST',
        data: {
          ...data,
          app: 'prisma',
          platform: 'web',
          redirect_uri: window.location.origin,
        },
      })
      .then(({ token, user_id, email }) => ({
          token,
          email,
          userId: user_id,
        })
      )

  public hiddenAuth = (data: IHiddenAuthRequest): Promise<IHiddenAuthResult> =>
    this.service
      .request({
        url: '/auth/by-key',
        method: 'POST',
        data: {
          ...data
        },
      })
      .then(({ token, user_id, email, refresh_token, status }) => ({
          token,
          email,
          userId: user_id,
          refreshToken: refresh_token,
          status
        })
      )
}
