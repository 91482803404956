import SectionBase from 'src/common/api/sections/base'
import { IConcreteSubPlan } from 'src/store/models/sub-plan'
import { STRIPE_ENTITY_CONFIGURATION } from 'src/common/constants/subscription/config'
import { PAYMENT_METHOD_TYPES } from 'src/common/constants/subscription/payment'

import storeInstance from 'src/store/instance'

import {
  ICreateSubscriptionOptions,
} from './types'

export default class Subscriptions extends SectionBase {
  public createStripeSession = ({
    billing_details,
  }: ICreateSubscriptionOptions) => {
    const userID: string = storeInstance.get().session.userID

    const data: any = {
      payment_method_types: PAYMENT_METHOD_TYPES,
      cancel_url: 'https://develop.prismalandings.pages.dev/#cancelled',
      success_url: "https://develop.prismalandings.pages.dev/checkout/store?plan=no-trial_yearly_no-discount&exp_name=no_name_experiment#success",
      user_id: userID,
      appsflyer_id: 'AppsflyerID1',
      subscription_data: [
        {
          plan: STRIPE_ENTITY_CONFIGURATION.PLAN,
          quantity: 1,
        },
      ],
      customer_email: billing_details.email,
    }

    return this.service.request({
      url: '/create_web_session',
      method: 'POST',
      data,
    })
  }

  public createSubscription = (paymentMethodId: string, concretePlan: IConcreteSubPlan) => {
    const data: any = Object.assign({
      payment_method_id: paymentMethodId,
      plan_id: STRIPE_ENTITY_CONFIGURATION.PLAN
    }, concretePlan)

    return this.service.request({
      url: '/subscription/stripe/create',
      method: 'POST',
      data,
    }).then((response) => {
      return {
        clientSecret: response.client_secret,
        expirationDate: response.expiration_date,
        paymentIntentStatus: response.payment_intent_status
      }
    })
  }
}
