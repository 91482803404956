import styled from 'src/common/styles'

import { TypographyProps } from './types'

export const StyledTypography = styled.div<TypographyProps>`
  color: ${(props) => props.theme.palette.label.primary};
  font-family: ${(props) => props.theme.typography.fontFamily};

  ${(props) => {
    if (!props.view) {
      return ''
    }
    const properties = props.theme.typography[props.view]
    return ` 
      font-size: ${properties.fontSize};
      font-weight: ${properties.fontWeight};
      line-height:  ${properties.lineHeight};
      letter-spacing:  ${properties.letterSpacing};
    `
  }}
`
