import React  from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { ElementsConsumer, CardNumberElement } from '@stripe/react-stripe-js';
import { QuickPaymentButton } from '../quick-payment-button'
import { CardElementsWrapper, CardNumber, CardExpiry, CardCvc, PayButton, cardStyles } from './styles'
import { Stripe as StripeType, StripeElements as StripeElementsType } from '@stripe/stripe-js/types';
import { IStoresMap } from 'src/store/mst'
import history from 'src/router/history'
import connector from 'src/common/decorators/connector'
import { LandingContextConsumer } from 'src/common/decorators/landingContext'
import { PAYMENT_INTENT_STATUSES } from 'src/common/constants/subscription/payment'
import { SubPlan, SUB_PLAN_CONFIGURATION} from 'src/common/constants/subscription/plans'

interface ICheckoutFormStripeProps {
  stripe: StripeType
  elements: StripeElementsType
}

interface ICheckoutFormState {
  loading: boolean
  paymentRequest: any | null
  numberFilled: boolean
  expiryFilled: boolean
  cvcFilled: boolean
}

type ICheckoutFormProps = RouteComponentProps & ReturnType<typeof storesToProps> & ICheckoutFormStripeProps

class CheckoutForm extends React.Component<ICheckoutFormProps, ICheckoutFormState> {
  constructor(props: ICheckoutFormProps) {
    super(props)
    const { stripe } = this.props
    if (!stripe) {
      history.push({
        pathname: '/first/auth',
        search: this.props.location.search
      })
    }

    this.state = {
      loading: false,
      paymentRequest: null,
      numberFilled: false,
      expiryFilled: false,
      cvcFilled: false
    }
  }

  public handleSubmit = async (event: React.SyntheticEvent, landing: string) => {
    event.preventDefault();
    this.setState({ loading: true })
    const {elements, subscription, authentication: { email }} = this.props

    if (!elements) return

    const purchaseResult = await subscription.purchase({
      card: elements.getElement(CardNumberElement),
      billing_details: { email }
    }, SUB_PLAN_CONFIGURATION[SubPlan.trialFree])
    
    switch (purchaseResult.status) {
      case PAYMENT_INTENT_STATUSES.succeeded: 
        history.push({
          pathname: `${landing}/payment-succeed`,
          search: this.props.location.search
         })
        break
      case PAYMENT_INTENT_STATUSES.error:
        this.onError()
        break
      default:
        this.onError()
        break
    }
  }

  public onError = () => {
    setTimeout(() => {
      this.setState({loading: false})
    }, 5000)
  }

  public render() {
    const { stripe, elements } = this.props
    const { numberFilled, expiryFilled, cvcFilled } = this.state

    return (
      <LandingContextConsumer>
        {(landing) => (
          <>
            <CardElementsWrapper>
              <CardNumber
                onChange={({ complete }) => {this.setState({numberFilled: complete})}}
                options={{ style: cardStyles, placeholder: 'Card number' }}
              />
              <CardExpiry
                onChange={({ complete }) => {this.setState({expiryFilled: complete})}}
                options={{ style: cardStyles, placeholder: 'Expires on' }}
              />
              <CardCvc
                onChange={({ complete }) => {this.setState({cvcFilled: complete})}}
                options={{ style: cardStyles }}
              />
              <PayButton
                isLoading={this.state.loading}
                onClick={(event) => {this.handleSubmit(event, landing)}}
                fullWidth
                disabled={!stripe || !numberFilled || !expiryFilled || !cvcFilled}
              >
                Continue
              </PayButton>
            </CardElementsWrapper>

            <QuickPaymentButton stripe={stripe} elements={elements}/>
          </>
        )}
      </LandingContextConsumer>
    );
  }
}

const storesToProps = ({ store }: IStoresMap, _nextProps: any) => ({
  subscription: store.subscription,
  authentication: store.authentication,
})

const CheckoutFormWithProps = withRouter(connector(storesToProps)(CheckoutForm))

export const InjectedCheckoutForm = () => (
  <ElementsConsumer>
    {({stripe, elements}) => (
      <CheckoutFormWithProps stripe={stripe} elements={elements} />
    )}
  </ElementsConsumer>
);
