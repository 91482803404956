import { FlowStep } from '../../constants/flow';

export const getErrorMessage = (e: any) => e?.response?.statusText || e?.message

// todo переписать на константы
export const getErrorObject = (e: string, step: string) => {
  const errorObject = {
    title: 'Something went wrong',
    text: e,
    type: 'default'
  }

  if (e === 'Network Error') {
    errorObject.title = 'No connection'
    errorObject.text = 'Check your internet connection'
    errorObject.type = 'network'
  }

  if (step === FlowStep.payment) {
    errorObject.text = 'Please retry payment'
  } else if (e.includes('with status code')) {
    errorObject.text = 'Please retry later'
  }

  return errorObject
}