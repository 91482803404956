import { Instance, types } from 'mobx-state-tree'

import { Session } from './session'

import { Authentication } from './authentication'
import { Subscription } from './subscription'
import { Flow } from './flow'
import { Base } from './base'

const Store = types
  .model({
    base: types.optional(Base, {}),
    session: types.optional(Session, {}),
    authentication: types.optional(Authentication, {}),
    subscription: types.optional(Subscription, {}),
    flow:types.optional(Flow, {}),
  })

export default Store

export interface IStore extends Instance<typeof Store> {}

export interface IStoresMap {
  store: IStore
}

