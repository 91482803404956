import React, { Component } from 'react'

import { Provider } from 'mobx-react'
import { Router as ReactRouter } from 'react-router-dom'

import Router from 'src/router'
import history from 'src/router/history'
import storeInstance from 'src/store/instance'
import { theme, ThemeProvider } from 'src/common/styles'
import GlobalStyles from 'src/common/styles/global'

import GoogleAnalytics from 'src/common/utils/analytics/google-analytics/initialization'
import FacebookAnalytics from 'src/common/utils/analytics/facebook-analytics/initialization'
import AmplitudeAnalytics from 'src/common/utils/analytics/amplitude-analytics/initialization'

import Error from 'src/pages/error'

import 'assets/css/font.css'

class App extends Component {
  public render() {
    // @todo maybe need to move analytics initializers to specific page(s)
    return (
      <>
        <GoogleAnalytics/>
        <FacebookAnalytics/>
        <AmplitudeAnalytics/>
        <ThemeProvider theme={theme}>
          <Provider store={storeInstance.get()}>
            <ReactRouter history={history}>
              <Router/>
              <Error />
            </ReactRouter>
          </Provider>
          <GlobalStyles/>
        </ThemeProvider>
      </>
    )
  }
}

export default App
