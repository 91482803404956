import React, { Component } from 'react'

import {
  Redirect,
  Route,
  RouteComponentProps,
  Switch,
  withRouter,
} from 'react-router-dom'


import ROUTES from 'src/router/constants'
import {LandingContextProvider} from 'src/common/decorators/landingContext'

import LandingPage from 'src/pages/base-landing'
import FirstLandingRouter from 'src/landings/first-landing/router'

class Router extends Component<RouteComponentProps> {
  public render() {
    return (
      <Switch>
        <Route exact path={ROUTES.APP} component={LandingPage} />

        <Route path={ROUTES.FIRST_LANDING}>
          <LandingContextProvider landing={ROUTES.FIRST_LANDING}>
            <FirstLandingRouter/>
          </LandingContextProvider>
        </Route>

        <Redirect to={ROUTES.APP}/>
      </Switch>
    )
  }
}

export default withRouter(Router)
