import React, { useEffect, useState } from 'react'
import { PaymentRequestButtonElement } from '@stripe/react-stripe-js';
import { Stripe as StripeType, StripeElements as StripeElementsType } from '@stripe/stripe-js/types';
import { Divider } from 'src/pages/auth/styles'

interface IQuickPaymentButtonProps {
  stripe: StripeType
  elements: StripeElementsType
}

export const QuickPaymentButton: React.FC<IQuickPaymentButtonProps> = ({
  stripe,
  elements
} : IQuickPaymentButtonProps) => {
  const [paymentRequest, setPaymentRequest] = useState(null)

  useEffect(() => {
    if (!stripe || !elements) return

    const paymentRequestEx = stripe.paymentRequest({
      country: 'US',
      currency: 'usd',
      total: {
        label: 'Prisma subscription',
        amount: 1
      },
      requestPayerName: true,
      requestPayerEmail: true,
    })

    paymentRequestEx.canMakePayment().then((result) => {
      if (result) {
        setPaymentRequest(paymentRequest)
      }
    })
  }, [stripe, elements])


  return (
    <>
      {paymentRequest && <>
        <Divider>or</Divider>
        <PaymentRequestButtonElement options={{paymentRequest}}/>
      </>}
    </>
  )
}

export default QuickPaymentButton
