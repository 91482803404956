// Base routes
const APP = '/'

// Landings list
const FIRST_LANDING = '/first'

export const ROUTES = {
  APP,
  FIRST_LANDING
}

export default ROUTES
