import React from 'react'

import { IStoresMap } from 'src/store/mst'
import connector from 'src/common/decorators/connector'

import {
  Divider,
  BlockBody,
  SubTitle,
  QuickLoginForm
} from '../styles'

import Checkmark from './checkmark'
import AppleAuth from '../auth-options/apple-auth'
import GoogleAuth from '../auth-options/google-auth'
import EmailAuth from '../auth-options/email-auth'

interface IRegistrationProps {
    location?: {
        search: string
    }
}

type RegistrationPropsType = IRegistrationProps & ReturnType<typeof storesToProps>

class RegistrationBlock extends React.Component<RegistrationPropsType, {}> {
  public render() {
    const { authentication } = this.props
    return (
      <BlockBody>
        <SubTitle>Enter your email to sign in <br/>or create an account</SubTitle>

        <EmailAuth/>

        <Divider>or</Divider>

        <QuickLoginForm>
          <AppleAuth colorTheme={'light'} location={this.props.location} />
          <GoogleAuth colorTheme={'light'} location={this.props.location} />
        </QuickLoginForm>

        <Checkmark
          checked={authentication.allowEmailSend}
          onClick={() =>
            authentication.setAllowEmailSend(!authentication.allowEmailSend)
          }
        >
          Get emails with tips & tutorials
        </Checkmark>
      </BlockBody>
    )
  }
}

const storesToProps = ({ store }: IStoresMap, _nextProps: any) => ({
  authentication: store.authentication
})

export default connector(storesToProps)(RegistrationBlock)
