import React from 'react'

import { RouteComponentProps, withRouter } from 'react-router-dom'
import history from 'src/router/history'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { Carousel } from 'react-responsive-carousel'
import { sendEvent } from 'src/common/utils/analytics'
import { EVENTS_AMPLITUDE } from 'src/common/constants/analytics/events'
import { AMPLITUDE } from 'src/common/constants/analytics'

import Slide1 from 'assets/images/first-landing/image1.jpg'
import Slide1Styled from 'assets/images/first-landing/image1_styled.jpg'
import Slide2 from 'assets/images/first-landing/image2.jpg'
import Slide2Styled from 'assets/images/first-landing/image2_styled.jpg'
import Slide3 from 'assets/images/first-landing/image3.jpg'
import Slide3Styled from 'assets/images/first-landing/image3_styled.jpg'

import arrowForward from 'assets/icons/arrow_forward.svg'

import {
  Wrapper,
  Button,
  Content,
  Inner,
  Images,
  ImageWrapper,
  Image,
  BottomSheetWrapper,
  Title
} from './styles'

class FirstOnboarding extends React.Component<RouteComponentProps> {
  private carouselRef: React.RefObject<Carousel> = React.createRef()

  public componentDidMount() {
    this.sendAnalytic(0)
  }

  public sendAnalytic(selectIndex: number) {
    const params = new URLSearchParams(this.props.location.search)

    sendEvent(EVENTS_AMPLITUDE.ScreenShow, [AMPLITUDE], {
      screen_ordinal: selectIndex + 1,
      media_source: params.get('media_source'),
      campaign: params.get('campaign')
    })
  }

  public render() {
    return (
      <Wrapper>
        <Carousel
          ref={this.carouselRef}
          showArrows={false}
          showThumbs={false}
          swipeable={false}
          showStatus={false}
          onChange={(selectIndex) => {this.sendAnalytic(selectIndex)}}
        >
          <Content color={'#0C2129'}>
            <Inner>
              <Images>
                <ImageWrapper>
                  <Image src={Slide1} alt='Original image' />
                </ImageWrapper>
                <ImageWrapper>
                  <Image src={Slide1Styled} alt='Magic image' />
                </ImageWrapper>
              </Images>

              <BottomSheetWrapper>
                <Title>
                  Capture life’s <br/>brightest moments
                </Title>
                <Button
                  bg={'#59FDFF'}
                  onClick={() => this.carouselRef.current.increment()}
                  endIcon={arrowForward}
                >
                  Next
                </Button>
              </BottomSheetWrapper>
            </Inner>
          </Content>

          <Content color={'#251A34'}>
            <Inner>
              <Images>
                <ImageWrapper>
                  <Image src={Slide2} alt='Original image' />
                </ImageWrapper>
                <ImageWrapper>
                  <Image src={Slide2Styled} alt='Magic image' />
                </ImageWrapper>
              </Images>

              <BottomSheetWrapper>
                <Title>
                  Light up your
                  <br/>
                  portraits
                </Title>
                <Button
                  bg={'#B67FFF'}
                  onClick={() => this.carouselRef.current.increment()}
                  endIcon={arrowForward}
                >
                  Next
                </Button>
              </BottomSheetWrapper>
            </Inner>
          </Content>

          <Content color={'#0C3533'}>
            <Inner>
              <Images>
                <ImageWrapper>
                  <Image src={Slide3} alt='Original image' />
                </ImageWrapper>
                <ImageWrapper>
                  <Image src={Slide3Styled} alt='Magic image' />
                </ImageWrapper>
              </Images>

              <BottomSheetWrapper>
                <Title>
                  Discover beauty around&nbsp;you
                </Title>
                <Button bg={'#FA9675'} onClick={() => history.push({pathname: '/first/auth',
                                                                    search: this.props.location.search})}>
                  Get Started
                </Button>
              </BottomSheetWrapper>
            </Inner>
          </Content>

          <></>
        </Carousel>
      </Wrapper>
    )
  }
}

export default withRouter(FirstOnboarding)
