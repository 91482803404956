import React, { Component } from 'react'

import {
  Redirect,
  Route,
  RouteComponentProps,
  Switch,
  withRouter,
} from 'react-router-dom'

import ROUTES from 'src/router/constants'
import { ONBOARDING, AUTH, OFFER, PAYMENT, PAYMENT_FAILURE, PAYMENT_SUCCEED } from '../constants/routes'
import FirstOnboarding from 'src/pages/onboardings/first-onboarding'
import Auth from 'src/pages/auth'
import Offer from 'src/pages/offer'
import Payment from 'src/pages/payment'
import PaymentSucceed from 'src/pages/payment-results/payment-succeed'
import PaymentFailure from 'src/pages/payment-results/payment-failure'
import { RouteWithProps } from 'src/common/decorators/routeWithProps'
import connector from 'src/common/decorators/connector'
import { IStoresMap } from 'src/store/mst'

class FirstLandingRouter extends Component<RouteComponentProps> {
  public render() {
    return (
      <Switch>
        <RouteWithProps
          exact
          key={ONBOARDING}
          path={ONBOARDING}
          component={FirstOnboarding}
          landing={ROUTES.FIRST_LANDING}
        />

        <RouteWithProps
          exact
          key={AUTH}
          path={AUTH}
          component={Auth}
          landing={ROUTES.FIRST_LANDING}
        />

        <RouteWithProps
          exact
          key={OFFER}
          path={OFFER}
          component={Offer}
          landing={ROUTES.FIRST_LANDING}
        />

        <RouteWithProps
          exact
          key={PAYMENT}
          path={PAYMENT}
          component={Payment}
          landing={ROUTES.FIRST_LANDING}
        />

        <RouteWithProps
          exact
          key={PAYMENT_SUCCEED}
          path={PAYMENT_SUCCEED}
          component={PaymentSucceed}
          landing={ROUTES.FIRST_LANDING}
        />

        <RouteWithProps
          exact
          key={PAYMENT_FAILURE}
          path={PAYMENT_FAILURE}
          component={PaymentFailure}
          landing={ROUTES.FIRST_LANDING}
        />

        <Redirect to={ROUTES.APP}/>
      </Switch>
    )
  }
}

const storesToProps = ({ store }: IStoresMap, _nextProps: any) => ({
  session: store.session
})

export default withRouter(connector(storesToProps)(FirstLandingRouter))
