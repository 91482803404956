import React from 'react'
import { Route, RouteProps } from 'react-router-dom'

const renderMergedProps = (component: React.ComponentClass, ...rest: any[]) => {
  const finalProps = Object.assign({}, ...rest);
  return (
    React.createElement(component, finalProps)
  );
}

interface IRouteWithProps {
  component: React.ComponentClass
  landing: string
}

type RouteWithPropsType = RouteProps & IRouteWithProps

export const RouteWithProps = ({ component, ...rest } : RouteWithPropsType) => {
  return (
    <Route {...rest} render={routeProps => {
      return renderMergedProps(component, routeProps, rest);
    }}/>
  )
}