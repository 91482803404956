import { applySnapshot, flow, Instance, types } from 'mobx-state-tree'

import API from 'src/common/api'
import { getErrorMessage } from 'src/common/utils/errors'
import { TokenType } from 'src/common/constants/authentication'
import { INTERNAL_ERROR } from 'src/common/constants/errors'

import { RootAccessing } from '../models/root-accessing'

export const AuthenticationModel = types
  .model('AuthenticationModel', {
    email: types.optional(types.string, ''),
    userId: types.optional(types.string, ''),
    allowEmailSend: types.optional(types.boolean, true),

    error: types.optional(types.string, ''),

    loadingApple: types.optional(types.boolean, false),
  })

export const Authentication = types
  .compose(AuthenticationModel, RootAccessing)
  .actions((self) => ({
    setAllowEmailSend(allow: boolean) {
      self.allowEmailSend = allow
    },
    setError(error: string) {
      self.error = error
    },
  }))
  .actions(self => ({
    authByEmail: flow(function* (email) {
      try {
        self.setError('')
        self.rootStore.base.setError('')

        self.userId = yield API.auth.authByEmail({ email })
        self.rootStore.session.setUserId(self.userId)

        self.email = email
      } catch (e) {
        self.rootStore.base.setError(getErrorMessage(e))
        throw e
      }
    }),
    confirmByEmail: flow(function* ({ code }) {
      try {
        self.setError('')
        self.rootStore.base.setError('')

        const token = yield API.auth.confirmByEmail({
          key: code,
          user_id: self.userId
        })

        self.rootStore.session.setSession(self.userId, token, TokenType.email)
      } catch (e) {
        self.rootStore.base.setError(getErrorMessage(e))
        throw e
      }
    }),
  }))
  .actions(self => ({
    authByGoogle: flow(function* (code) {
      try {
        self.setError('')
        self.rootStore.base.setError('')

        const { userId, email, token } = yield API.auth.authByGoogle({ code })
        self.email = email

        self.rootStore.session.setSession(userId, token, TokenType.google)
      } catch (e) {
        self.rootStore.base.setError(getErrorMessage(e))
        throw e
      }
    }),
  }))
  .actions(self => ({
    authByApple: flow(function* ({ error, userId, token }) {
      self.setError('')
      self.rootStore.base.setError('')
      self.loadingApple = true

      if (error) {
        if (error !== INTERNAL_ERROR) {
          self.rootStore.base.setError(error)
        }
        throw error
      }

      self.rootStore.session.setSession(userId, token, TokenType.apple)

      self.loadingApple = false
    }),
  }))
  .actions((self) => ({
    restartFlow() {
      applySnapshot(self, {})
    }
  }))

export interface IAuthentication extends Instance<typeof Authentication> {}
