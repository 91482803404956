import {
  AMPLITUDE,
  FACEBOOK,
  FACEBOOK_ANALYTICS_PIXEL_ID,
} from 'src/common/constants/analytics'

export const sendEvent = (
  event: string,
  destination: string[],
  params?: any
) => {
  destination.forEach((item) => {
    switch (item) {
      // case GOOGLE_ANALYTICS:
      //   if (params) {
      //     params.event_category = 'general'
      //     params.event_label = event
      //     gtag('event', event, params)
      //   } else {
      //     gtag('event', event)
      //   }
      //   break
      case AMPLITUDE:
        if (params) {
          amplitude.getInstance().logEvent(event, params)
        } else {
          amplitude.getInstance().logEvent(event)
        }
        break
      case FACEBOOK:
        if (params) {
          fbq('trackSingle', FACEBOOK_ANALYTICS_PIXEL_ID, event, params)
        } else {
          fbq('trackSingle', FACEBOOK_ANALYTICS_PIXEL_ID, event)
        }
        break
    }
  })
}

export const setUserIdToAnalytics = (userID: string) => {
  // gtag('config', GOOGLE_ANALYTICS_ID, { user_id: userID })
  amplitude.getInstance().setUserId(userID)
  fbq('init', FACEBOOK_ANALYTICS_PIXEL_ID, { uid: userID })
}
