import React from 'react'

import connector from 'src/common/decorators/connector'
import { Button2, ButtonSize, ButtonView } from 'src/common/components/button'
import { IStoresMap } from 'src/store/mst'

import { InputWrapper, SubTitle } from '../../styles'

import InputVerificationCode, { CODE_LENGTH } from './input-verification-code'
import history from "src/router/history";
import { LandingContextConsumer } from 'src/common/decorators/landingContext'

export const CODE_ERRORS = {
  IncorrectCode: 'Invalid Code',
}

interface IFormProps {
  location?: {
    search: string
  }
}

type FormPropsType = IFormProps & ReturnType<typeof storesToProps>

export interface IFormState {
  code: string
  loading: boolean
}

class Form extends React.Component<FormPropsType, IFormState> {
  public constructor(props: FormPropsType) {
    super(props)

    this.state = {
      code: '',
      loading: false
    }
  }

  public isCodeValid(code: string): string {
    return code.length === CODE_LENGTH ? '' : CODE_ERRORS.IncorrectCode
  }

  public handleSubmit = async (e: React.FormEvent<HTMLFormElement>, landing: string) => {
    e?.preventDefault()

    const { code } = this.state
    const { authentication, flow } = this.props

    const error = this.isCodeValid(code)
    if (error) {
      authentication.setError(error)
      return
    }

    try {
      this.setState({ loading: true })
      await authentication.confirmByEmail({ code })
      await flow.afterAuthentication(
        authentication.email,
        authentication.allowEmailSend
      )
      history.push({
        pathname:`${landing}/offer`,
        search: this.props.location.search
      })
    } catch (e) {
      throw e
    }
  }

  public render() {
    const {
      authentication: { email, error },
    } = this.props
    const { code, loading } = this.state

    return (
      <LandingContextConsumer>
        {(landing) => (
          <form onSubmit={(event) => {this.handleSubmit(event, landing)}}>
            <SubTitle>
              An email with the code has been sent {email && `to ${email}`}
            </SubTitle>

            <InputWrapper>
              <InputVerificationCode
                code={code}
                error={error}
                onChange={(c) => this.setState({ code: c })}
              />
            </InputWrapper>

            <Button2
              id="prisma_checkout-flow_confirm_btn-submit"
              view={ButtonView.CTA}
              size={ButtonSize.Large}
              isLoading={loading}
              fullWidth
            >
              Submit
            </Button2>
          </form>
        )}
      </LandingContextConsumer>

    )
  }
}

const storesToProps = ({ store }: IStoresMap, _nextProps: any) => ({
  authentication: store.authentication,
  flow: store.flow
})

export default connector(storesToProps)(Form)
