import React from 'react'

import { IStoresMap } from 'src/store/mst'
import connector from 'src/common/decorators/connector'
import { Button2, ButtonSize, ButtonView } from 'src/common/components/button'
import { FlowStep } from 'src/common/constants/flow'
import { Divider, BlockBody } from '../styles'

import Form from './form'

interface IConfirmationProps {
  location?: {
    search: string
  }
}

type ConfirmationPropsType = IConfirmationProps & ReturnType<typeof storesToProps>

class ConfirmationBlock extends React.Component<ConfirmationPropsType> {
  public handleRestart = () => {
    const { authentication, flow } = this.props
    flow.setStep(FlowStep.registration)
    authentication.restartFlow()
  }

  public render() {
    return (
      <BlockBody>
        <Form location={this.props.location}/>

        <Divider>or</Divider>

        <Button2
          id="prisma_checkout-flow_confirm_btn-try-again"
          view={ButtonView.Secondary}
          size={ButtonSize.Large}
          onClick={this.handleRestart}
          fullWidth
        >
          Didn't get the code? Try again
        </Button2>
      </BlockBody>
    )
  }
}

const storesToProps = ({ store }: IStoresMap, _nextProps: any) => ({
  flow: store.flow,
  authentication: store.authentication
})

export default connector(storesToProps)(ConfirmationBlock)
