import { Platform } from './platforms'
import { Application } from './applications'

export const STORE_LINKS = {
  [Platform.android]: 'https://play.google.com/store/apps/details?id=com.neuralprisma',
  [Platform.ios]: 'https://apps.apple.com/app/id1122649984',
}

export const WEB_LINKS = {
  [Application.prisma]: 'https://prisma-ai.com/prisma',
  [Application.lensa]: 'https://prisma-ai.com/lensa',
}

export const MAIN_SITE = 'https://prisma-ai.com/'
