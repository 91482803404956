import styled from 'src/common/styles'
import { Typography } from 'src/common/components/typography'
import { Wrapper } from '../auth/styles'

export const HeartWrapper = styled.div`
  width: 100%;

  display: flex;
  justify-content: center;

  margin-top: 16px;
  margin-bottom: 8px;
`

export const ThankYouMessage = styled(Typography)`
  text-align: center;
  margin-bottom: 8px;
`

export const Message = styled(Typography)`
  color: rgba(63, 63, 69, 0.72);
  text-align: center;
  margin-bottom: 32px;
`

export const StoreWrapper = styled.div`
  width: 100%;

  display: flex;
  justify-content: center;

  margin-bottom: 32px;
`

export const BlockWrapper = styled(Wrapper)`
  align-items: center;
`

export const BlockBody = styled.div`
  width: 320px;
  padding: 24px 16px 16px 16px;
  background-color: ${(props) => props.theme.palette.background.base};
  overflow: hidden;
  box-shadow: ${(props) => props.theme.shadows.float};
  border-radius: 16px;
  position: relative;
  z-index: 1;
`

export const BlockTitle = styled.div`
  display: flex;
  justify-content: center;

  padding: 16px;

  border-bottom: 1px solid ${(props) => props.theme.palette.fill.secondary};
`