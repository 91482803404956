export const PAYMENT_METHOD_TYPES = 'card'

export enum PAYMENT_INTENT_STATUSES {
  succeeded = 'succeeded',
  canceled = 'canceled',
  processing = 'processing',
  error = 'error',
  requiresAction = 'requires_action',
  requiresCapture = 'requires_capture',
  requiresConfirmation = 'requires_confirmation',
  requiresPaymentMethod = 'requires_payment_method',
}