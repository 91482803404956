import styled from 'src/common/styles'

import { rgba } from 'polished'

import { Loader as BaseLoader } from 'src/common/components/loader'
import { TypographyView } from 'src/common/components/typography'

import { Button2Props, ButtonSize, ButtonView } from './types'

export const BaseButton = styled.button<{ theme?: string }>`
  color: transparent;
  background-color: transparent;

  border: none;
  outline: none;

  border-radius: 10px;

  cursor: pointer;

  &:hover:not(:disabled) {
    transition: background-color ease-out 100ms;
  }

  &:active:not(:disabled) {
    transition: background-color ease-out 50ms;
  }

  &:disabled {
    cursor: auto;
  }
`

export const StyledButton = styled(BaseButton)<Button2Props>`
  display: flex;
  align-items: center;
  justify-content: center;

  font-family: ${(props) => props.theme.typography.fontFamily};

  ${(props) => {
    let view = TypographyView.Headline

    if (props.view !== ButtonView.CTA) {
      view = TypographyView.BodyMedium
    }

    return `
      font-size: ${props.theme.typography[view].fontSize};
      font-weight: ${props.theme.typography[view].fontWeight};
      line-height: ${props.theme.typography[view].lineHeight};
      letter-spacing: ${props.theme.typography[view].letterSpacing};
    `
  }}

  /* VIEW */
  ${(props) => {
    switch (props.view) {
      case ButtonView.CTA:
        return `
      color: ${props.theme.palette.label.onAccent};
      background-color: ${props.theme.palette.accent.base};
      
      &:hover:not(:disabled) {
        background-color: #84E707;
      }
      
      &:active:not(:disabled) {
        background-color: #6EC600;
      }
      
      &:disabled {
        opacity: 0.5;
        
        svg {
          opacity: 0.5;
        }
      }
      
      svg {
        fill: ${props.theme.palette.label.onAccent};
      }
      
      > .loader {
        border-color: ${props.theme.palette.label.onAccent};
      }
    `
      case ButtonView.Primary:
        return `
      color: ${props.theme.palette.background.base};
      background-color: ${props.theme.palette.label.primary};
      
      &:hover:not(:disabled) {
        color: ${rgba(props.theme.palette.background.base, 0.7)};
        
        svg {
          fill: ${rgba(props.theme.palette.background.base, 0.7)};
        }
      }
      
      &:active:not(:disabled) {
        color: ${rgba(props.theme.palette.background.base, 0.5)};
        
        svg {
          fill: ${rgba(props.theme.palette.background.base, 0.5)};
        }
      }
      
      &:disabled {
        color: ${props.theme.palette.label.tertiary};
        background-color: ${props.theme.palette.fill.quaternary};
        
        svg {
          fill: ${props.theme.palette.label.tertiary};
        }
      }
      
      svg {
        fill: ${props.theme.palette.background.base};
      }
      
      > .loader {
        border-color: ${props.theme.palette.background.base};
      }
    `
      case ButtonView.Secondary:
        return `
      color: ${props.theme.palette.label.primary};
      background-color: ${props.theme.palette.white[100]};
      
      &:hover:not(:disabled) {
        background-color: ${props.theme.palette.background.cellar};
      }
      
      &:active:not(:disabled) {
        background-color: ${props.theme.palette.fill.primary};
      }
      
      &:disabled {
        color: ${props.theme.palette.label.tertiary};
        background-color: ${props.theme.palette.white[90]};
        
        svg {
          fill: ${props.theme.palette.label.tertiary};
        }
      }
      
      svg {
        fill: ${props.theme.palette.label.primary};
      }
      
      > .loader {
        border-color: ${props.theme.palette.label.primary};
      }
    `
      case ButtonView.Danger:
        return `
      color: ${props.theme.palette.error.base};
      background-color: rgba(255, 91, 91, 0.12); /* rgba(253, 99, 99, 0.12); */
      
      &:hover:not(:disabled) {
        background-color: rgba(255, 91, 91, 0.2); /* rgba(253, 99, 99, 0.2); */
      }
      
      &:active:not(:disabled) {
        background-color: ${props.theme.palette.error.trail};
      }
      
      &:disabled {
        opacity: 0.5;
      }
      
      svg {
        fill: ${props.theme.palette.error.base};
      }
      
      > .loader {
        border-color: ${props.theme.palette.error.base};
      }
    `
      case ButtonView.Text:
        return `
      color: ${props.theme.palette.label.primary};

      &:hover:not(:disabled) {
        background-color: ${props.theme.palette.fill.quaternary};
      }
      
      &:active:not(:disabled) {
        background-color: ${props.theme.palette.fill.secondary};
      }
      
      &:disabled {
        color: ${props.theme.palette.label.tertiary};
        background-color: ${props.theme.palette.fill.quaternary};
        
        svg {
          fill: ${props.theme.palette.label.tertiary};
        }
      }
      
      svg {
        fill: ${props.theme.palette.label.primary};
      }
      
      > .loader {
        border-color: ${props.theme.palette.label.primary};
      }
    `
      default:
        return ``
    }
  }}

  ${(props) => {
    switch (props.size) {
      case ButtonSize.Small:
        return `
        height: 32px;
        padding: 6px 12px;
      `
      case ButtonSize.Medium:
        return `
        height: 40px;
        padding: 10px 16px;
      `
      case ButtonSize.Large:
        return `
        height: 48px;
        padding: 14px 14px;
      `
      default:
        return ``
    }
  }}
  
  /* FULL WIDTH */
  ${(props) => props.fullWidth && 'width: 100%;'}

  /* LOADER */
  ${(props) => props.isLoading && `pointer-events: none;`}
  
  & > .loader {
    border-bottom-color: transparent;
  }

  /* ICONS */
  & svg {
    margin: 0 6px;

    &.icon-start {
      margin-left: 0;
    }

    &.icon-end {
      margin-right: 0;
    }
  }
`

export const ButtonText = styled.span<{ isLoading: boolean }>`
  opacity: ${(props) => (props.isLoading ? 0 : 1)};
`

export const Loader = styled(BaseLoader)`
  position: absolute;
`
