import React from 'react';
const { Provider, Consumer } = React.createContext('first');

export interface ILandingContextProviderProps {
  landing: string
}

class LandingContextProvider extends React.Component<ILandingContextProviderProps> {
  render() {
    return (
      <Provider value={this.props.landing}>
        {this.props.children}
      </Provider>
    );
  }
}

export { LandingContextProvider, Consumer as LandingContextConsumer };
