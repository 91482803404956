export const EVENTS_FACEBOOK = {
  RegisterSuccess: 'CompleteRegistration',

  SubscriptionPurchaseSuccess: 'Purchase',

  StoreLinkTap: 'SubmitApplication',
}

export const EVENTS_AMPLITUDE = {
  ScreenShow: 'landing_screen_show',
  ActionShow: 'landing_action_show',
  ActionButtonTap: 'landing_action_tap',
  InstallButtonTap: 'landing_install_tap',

  RegisterShow: 'onboarding_register_show',
  RegisterButtonTap: 'onboarding_register_tap',
  RegisterSuccess: 'onboarding_register_success',

  StripeShow: 'onboarding_paywall_show',

  SubscriptionPurchaseSuccess: 'purchase_success',

  StoreLinkShow: 'onboarding_download_show',
  StoreLinkTap: 'onboarding_download_tap',
}
