import { ICreateSubscriptionOptions } from 'src/common/api/sections/subscriptions/types'
import { PAYMENT_METHOD_TYPES } from 'src/common/constants/subscription/payment'
import { DEFAULT_TRIAL_PERIOD_DAYS, TrialType } from 'src/common/constants/subscription/trial'
import { STRIPE_ENTITY_CONFIGURATION } from 'src/common/constants/subscription/config'
import { ISubPlan, IConcreteSubPlan } from 'src/store/models/sub-plan'

export const preFillSubscriptionOptions = (
  options: ICreateSubscriptionOptions,
  email: string
) => {
  const localOptions = { ...options }

  if (!localOptions.type) {
    localOptions.type = PAYMENT_METHOD_TYPES
  }

  if (!localOptions.billing_details) {
    localOptions.billing_details = {
      email
    }
  }
  
  return localOptions
}

export const setPlanOptions = (plan: ISubPlan) => {
  const { trialType, coupon } = plan
  const data: IConcreteSubPlan = {}

  if (trialType !== TrialType.none) {
    data.trial_period_days = DEFAULT_TRIAL_PERIOD_DAYS
  }

  if (trialType === TrialType.paid) {
    data.subscription_data.plan = STRIPE_ENTITY_CONFIGURATION.PLAN1$
    data.subscription_data.quantity = 1
  }

  if (coupon) {
    data.coupon = coupon
  }

  return data
}