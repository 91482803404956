import { Instance } from 'mobx-state-tree'

import { TokenType } from 'src/common/constants/authentication'
import { setUserIdToAnalytics } from 'src/common/utils/analytics'

import { BaseSession } from '../index'

export default (self: Instance<typeof BaseSession>) => ({
  setUserId(id: string) {
    self.userID = id
  },
  setApp(id: string) {
    self.app = id
  },
  setSession(userId: string, tokenValue: string, tokenType: TokenType) {
    self.userID = userId
    self.token = tokenValue
    self.tokenType = tokenType

    setUserIdToAnalytics(self.userID)
  },
})
