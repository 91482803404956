import React from 'react';
import { IStoresMap } from 'src/store/mst'
import connector from 'src/common/decorators/connector'
import { ButtonGoogleLogin } from 'src/common/components/button-google-login'
import history from 'src/router/history'
import { LandingContextConsumer } from 'src/common/decorators/landingContext'
import { sendEvent } from 'src/common/utils/analytics'
import { EVENTS_AMPLITUDE } from 'src/common/constants/analytics/events'
import { AMPLITUDE } from 'src/common/constants/analytics'

interface IGoogleThemeProps {
  colorTheme?: string,
  location?: {
    search: string
  }
}

interface IGoogleAuthState {
  loading: boolean
}

type GoogleAuthPropsType = IGoogleThemeProps & ReturnType<typeof storesToProps>

class GoogleAuth extends React.Component<GoogleAuthPropsType, IGoogleAuthState> {
  state = {
    loading: false
  }

  public handleGoogleClick = () => {
    this.setState({loading: true})
    this.sendAnalytic()
  }

  public sendAnalytic = () => {
    const params = new URLSearchParams(this.props.location.search)

    sendEvent(EVENTS_AMPLITUDE.ActionButtonTap, [AMPLITUDE], {
      context: 'sign_in',
      media_source: params.get('media_source'),
      campaign: params.get('campaign')
    })
  }

  public handleSubmit = async (code: any = {}, landing: string) => {
    try {
      const { authentication } = this.props
      await authentication.authByGoogle(code)
      history.push({
        pathname: `${landing}/offer`,
        search: this.props.location.search
      })
    } catch (e) {
      throw e
    }
  }

  public render() {
    const { colorTheme } = this.props

    return (
      <LandingContextConsumer>
        {(landing) => (
          <ButtonGoogleLogin
            id='prisma_checkout-flow_sign_btn-google-login'
            onSubmit={({ code }) => {this.handleSubmit(code, landing)}}
            loading={this.state.loading}
            onClick={() => {this.handleGoogleClick()}}
            colorTheme={colorTheme}
          />
        )}
      </LandingContextConsumer>
    )
  }
}

const storesToProps = ({ store }: IStoresMap, _nextProps: any) => ({
  authentication: store.authentication
})

export default connector(storesToProps)(GoogleAuth)