import { Instance, types } from 'mobx-state-tree'
import { RootAccessing } from '../models/root-accessing';

export const BaseModel = types
  .model('BaseModel', {
    error: types.optional(types.string, ''),
    showMessageTimer: types.optional(types.maybeNull(types.number), null),
  })

export const Base = types
  .compose(BaseModel, RootAccessing)
  .actions((self) => ({
    clearTimer() {
      clearTimeout(self.showMessageTimer)
    }
  }))
  .actions((self) => ({
    setError(error: string) {
      self.error = error
    },
    setTimer(func: any, time: number) {
      self.clearTimer()
      // @ts-ignore
      self.showMessageTimer = setTimeout(func, time)
    }
  }))

export interface IBase extends Instance<typeof Base> {}