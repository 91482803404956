import styled from 'src/common/styles'

import { Button2, ButtonSize } from 'src/common/components/button'
import { Typography, TypographyView } from 'src/common/components/typography'
import Svg from 'src/common/components/svg'

import bg from 'assets/images/first-landing/jellyfish.png'
import bgStars from 'assets/images/first-landing/stars.png'
import bgRightCoral from 'assets/images/first-landing/right-coral.png'
import bgLeftCoral from 'assets/images/first-landing/left-coral.png'

export const Wrapper = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
  max-height: -webkit-fill-available;
  display: flex;
  justify-content: center;
  background-color: #050423;
  overflow: clip;
  overflow: -webkit-paged-y;

  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    max-height: 720px;
    background-image: url(${bg});
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: center center;

    @media (min-height: 960px) {
      max-height: 960px;
    }
  }

  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 112%;
    background-image: url(${bgLeftCoral}), url(${bgRightCoral}), url(${bgStars});
    background-size: 71px auto, 213px auto, auto 90%;
    background-repeat: no-repeat, no-repeat, repeat;
    background-position: left bottom, right bottom, left top;

    @media (min-height: 720px) {
      height: 100%;
      background-size: 71px auto, 213px auto, auto 100%;
    }
  }
`

export const Content = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  height: 100%;
  width: 100%;
  max-height: 675px;
  max-width: 375px;
  margin: auto;
  padding: 0 32px 10px;
  z-index: 1;
`

export const Header = styled.div`
  position: relative;
  
  + button {
    margin-top: 24px;
  }
`

export const Title = styled(Typography).attrs({
  view: TypographyView.Title1,
})`
  text-align: center;
  background: -webkit-linear-gradient(93.32deg, #FE9CEE 6.9%, #FF988A 87.15%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`

export const ButtonView = styled(Button2).attrs({
  size: ButtonSize.Large,
  fullWidth: true,
})<{ colorTheme?: string }>`
  margin-top: 16px;
  background-color: ${(props) => props.colorTheme === 'light' ? '#1C1C1E' : '#FFFFFF'};
  color: ${(props) => props.colorTheme === 'light' ? '#FFFFFF' : '#000000'};
  ${(props) => props.theme.typography.objToCss(TypographyView.BodyMedium)}

  &:first-child {
    margin-top: 0;
  }
`

export const TransparentButtonView = styled(ButtonView)`
  background-color: rgba(118, 118, 128, 0.18);
  color: rgba(255, 255, 255, 0.96);
`

export const PrivacyTerms = styled(Typography).attrs({
  view: TypographyView.CaptionMedium,
})`
  margin-top: 10px;
  text-align: center;
  color: rgba(235, 235, 245, 0.64);
`

export const Link = styled.a`
  color: #FFFFFF;
  text-decoration: none;
`

export const HiddenContent = styled.div.attrs((props: { isOpen: boolean }) => ({
  style: {
    top: props.isOpen ? '24px' : '100%',
  },
}))<{ isOpen: boolean }>`
  position: absolute;
  top: 24px;
  width: 100%;
  height: 100%;
  padding: 24px 0;
  background: #FFFFFF;
  border-radius: 16px 16px 0 0;
  z-index: 2;
  transition: top ease-in-out 400ms;
  overflow: auto;
`

export const HiddenHeader = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 40px;
  padding: 0 24px;
`

export const HiddenBody = styled.div`
  max-width: 375px;
  margin: 0 auto;
  padding: 0 24px;
`

export const CloseButton = styled.button`
  position: absolute;
  top: -4px;
  left: 20px;
  width: 28px;
  height: 28px;
  background: rgba(116, 116, 128, 0.08);
  border-radius: 50%;
  
  /* Reset */
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
  overflow: visible;
  text-transform: none;
  -webkit-appearance: button;
  border: none;
`

export const CloseIcon = styled(Svg)`
  opacity: 0.6;
`

export const HeaderTitle = styled(Typography).attrs({
  view: TypographyView.Headline
})`
  text-align: center;
  color: #000000;
`

export const BlockBody = styled.div`
  padding: 0 0 24px;
`

export const SubTitle = styled(Typography).attrs({
  view: TypographyView.Headline
})`
  margin-bottom: 24px;
  color: #000000;
  text-align: center;
`

export const InputWrapper = styled.div`
  display: flex;
  margin-bottom: 14px;
`

export const Divider = styled(Typography).attrs({
  view: TypographyView.CaptionRegular
})`
  margin: 16px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  text-transform: uppercase;
  color: rgba(62, 62, 67, 0.3);

  &:before,
  &:after {
    content: '';
    width: 100%;
    height: 1px;
    flex-shrink: 1;
    margin: 0 10px;
    background-color: rgba(62, 62, 67, 0.3);
  }

  &:before {
    margin-left: 0;
  }

  &:after {
    margin-right: 0;
  }
`

export const QuickLoginForm = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 26px;
  
  button:not(:first-child) {
    margin-top: 16px;
  }
`