import React from 'react'

import { ComponentSize } from 'src/common/constants/web-kit/sizes'

import {
  EndIcon,
  ErrorMessage,
  InputContainer,
  InputWrapper,
  StartIcon,
  StyledInput,
} from './styles'
import { InputProps } from './types'

export const Input: React.ForwardRefExoticComponent<
  React.PropsWithoutRef<InputProps> & React.RefAttributes<HTMLInputElement>
> = React.forwardRef(
  (
    {
      className,
      type,
      value,
      placeholder,
      maxLength,
      min,
      max,
      startIcon,
      endIcon,
      error,
      showError,
      onChange,
      onFocus,
      onBlur,
      componentSize,
      fullWidth,
    }: InputProps,
    ref
  ) => (
    <InputContainer fullWidth={fullWidth} className={className}>
      {showError && (
        <ErrorMessage visible={Boolean(error)}>{error}</ErrorMessage>
      )}
      <InputWrapper>
        <StyledInput
          ref={ref}
          type={type || 'text'}
          min={min}
          max={max}
          value={value}
          placeholder={placeholder}
          maxLength={maxLength}
          error={error}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          startIcon={startIcon}
          endIcon={endIcon}
          componentSize={componentSize ?? ComponentSize.Default}
        />
        {startIcon && <StartIcon icon={startIcon} />}
        {endIcon && <EndIcon icon={endIcon} />}
      </InputWrapper>
    </InputContainer>
  )
)

export default Input
