import { flow, Instance, types } from 'mobx-state-tree'

import API from 'src/common/api'
import { FlowStep } from 'src/common/constants/flow'
import { sendEvent } from 'src/common/utils/analytics'
import { EVENTS_AMPLITUDE, EVENTS_FACEBOOK } from 'src/common/constants/analytics/events'
import { AMPLITUDE, FACEBOOK } from 'src/common/constants/analytics'
import { RootAccessing } from '../models/root-accessing'

export const FlowModel = types
  .model('FlowModel', {
    step: types.optional(
      types.enumeration('CheckoutFlowStep', [...Object.values(FlowStep)]),
      FlowStep.default
    ),
  })
  .actions((self) => ({
    setStep(step: FlowStep) {
      self.step = step
    }
  }))

export const Flow = types
  .compose(FlowModel, RootAccessing)
  .actions(self => ({
    afterAuthentication: flow(function* (email: string, allowed: boolean) {
      sendEvent(EVENTS_FACEBOOK.RegisterSuccess, [FACEBOOK])
      sendEvent(EVENTS_AMPLITUDE.RegisterSuccess, [AMPLITUDE])

      yield API.user.sendEmailAllowance(allowed)
      self.setStep(FlowStep.payment)
    }),
  }))

export interface IFlow extends Instance<typeof Flow> {}