import BASE_ROUTES from 'src/router/constants'

export const ONBOARDING = `${BASE_ROUTES.FIRST_LANDING}/onboarding`
export const AUTH = `${BASE_ROUTES.FIRST_LANDING}/auth`
export const OFFER = `${BASE_ROUTES.FIRST_LANDING}/offer`
export const PAYMENT = `${BASE_ROUTES.FIRST_LANDING}/payment`
export const PAYMENT_FAILURE = `${BASE_ROUTES.FIRST_LANDING}/payment-failure`
export const PAYMENT_SUCCEED = `${BASE_ROUTES.FIRST_LANDING}/payment-succeed`

const FIRST_LANDING_ROUTES = {
  ONBOARDING,
  AUTH,
  OFFER,
  PAYMENT,
  PAYMENT_FAILURE,
  PAYMENT_SUCCEED
}

export default FIRST_LANDING_ROUTES
