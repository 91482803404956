import React from 'react'
import {RouteComponentProps, withRouter} from 'react-router-dom'
import connector from 'src/common/decorators/connector'
import { IStoresMap } from 'src/store/mst'
import { getErrorObject } from 'src/common/utils/errors'
import ErrorMessage from './error-message'

type ErrorPropsType = RouteComponentProps & ReturnType<typeof storesToProps>

class Error extends React.Component<ErrorPropsType> {
  public componentDidMount() {
    window.addEventListener('offline', this.setOffline);
  }

  public setOffline = () => {
    const { base } = this.props

    base.setError('Network Error')
  }

  public render() {
    const { base: { error }, flow: { step } } = this.props

    return (
      error && <ErrorMessage {...getErrorObject(error, step)} />
    )
  }
}

const storesToProps = ({ store }: IStoresMap, _nextProps: any) => ({
  base: store.base,
  flow: store.flow
})

export default withRouter(connector(storesToProps)(Error))

