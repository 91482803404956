import React from 'react'

import MobileDetect from 'mobile-detect'
import { RouteComponentProps, withRouter } from 'react-router-dom'

import { IStoresMap } from 'src/store/mst'
import { TypographyView } from 'src/common/components/typography'
import Svg from 'src/common/components/svg'
import connector from 'src/common/decorators/connector'
import history from 'src/router/history'
import blackHeart from 'assets/images/checkout-flow/black_heart.svg'

import {BlockBody, BlockWrapper, HeartWrapper, Message, StoreWrapper, ThankYouMessage} from '../styles'
import {Button2, ButtonSize, ButtonView} from "../../../common/components/button";


interface IPaymentFailureProps {
  errorMessage: string
}
type PaymentFailurePropsType = IPaymentFailureProps & RouteComponentProps & ReturnType<typeof storesToProps>

class PaymentFailure extends React.Component<PaymentFailurePropsType> {
  constructor(props: PaymentFailurePropsType) {
    super(props)

    const { authentication } = this.props
    if (!authentication.email) {
      history.push({
        pathname: '/',
        search: this.props.location.search
      })
    }
  }

  public get md() {
    return new MobileDetect(window.navigator.userAgent)
  }

  public restart() {
    const { authentication } = this.props
    authentication.restartFlow()

    history.push({
      pathname: '/first/auth',
      search: this.props.location.search
    })
  }

  public render() {
    return (
      <BlockWrapper>
        <BlockBody>
          <HeartWrapper>
            <Svg icon={blackHeart}/>
          </HeartWrapper>

          <ThankYouMessage view={TypographyView.Title2}>
            Sorry!
          </ThankYouMessage>
          <Message view={TypographyView.BodyMedium}>
            Failed to verify the payment method. Please select a different payment method and try again.
          </Message>

          <StoreWrapper>
            <Button2
                id="prisma_checkout-flow_confirm_btn-try-again"
                view={ButtonView.Primary}
                size={ButtonSize.Large}
                onClick={() => {this.restart()}}
                fullWidth
            >
              Try again
            </Button2>
          </StoreWrapper>
        </BlockBody>
      </BlockWrapper>
    )
  }
}

const storesToProps = ({ store }: IStoresMap, _nextProps: any) => ({
  flow: store.flow,
  authentication: store.authentication,
})

export default withRouter(connector(storesToProps)(PaymentFailure))
