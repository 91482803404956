import React from 'react'

import MobileDetect from 'mobile-detect'
import { RouteComponentProps, withRouter } from 'react-router-dom'

import { IStoresMap } from 'src/store/mst'
import { TypographyView } from 'src/common/components/typography'
import Svg from 'src/common/components/svg'
import connector from 'src/common/decorators/connector'
import { sendEvent } from 'src/common/utils/analytics'
import { STORE_LINKS } from 'src/common/constants/links'
import { Platform } from 'src/common/constants/platforms'
import { AMPLITUDE } from 'src/common/constants/analytics'
import { EVENTS_AMPLITUDE } from 'src/common/constants/analytics/events'

import blackHeart from 'assets/images/checkout-flow/black_heart.svg'
import appStore from 'assets/images/stores/app_store_dark.svg'
import googlePlay from 'assets/images/stores/google_play_dark.svg'

import { BlockWrapper, BlockBody, HeartWrapper, Message, StoreWrapper, ThankYouMessage } from '../styles'
import history from "../../../router/history";

type PaymentSucceedPropsType = RouteComponentProps & ReturnType<typeof storesToProps>

class PaymentSucceed extends React.Component<PaymentSucceedPropsType> {
  constructor(props: PaymentSucceedPropsType) {
    super(props)

    const { authentication } = this.props
    if (!authentication.email) {
      history.push({
        pathname: '/',
        search: this.props.location.search
      })
    }
  }

  public get md() {
    return new MobileDetect(window.navigator.userAgent)
  }

  public handleAppClick = () => {
    this.sendAnalytic();
    document.location.href = STORE_LINKS[this.md.os() === 'iOS' ? Platform.ios : Platform.android]
  }

  public sendAnalytic() {
    const params = new URLSearchParams(this.props.location.search)

    sendEvent(EVENTS_AMPLITUDE.InstallButtonTap, [AMPLITUDE], {
      app_store: this.md.os() === 'iOS' ? 'apple' : 'google',
      media_source: params.get('media_source'),
      campaign: params.get('campaign')
    })
  }

  public render() {
    return (
      <BlockWrapper>
        <BlockBody>
          <HeartWrapper>
            <Svg icon={blackHeart}/>
          </HeartWrapper>

          <ThankYouMessage view={TypographyView.Title2}>
            Thank You!
          </ThankYouMessage>
          <Message view={TypographyView.BodyMedium}>
            Enjoy your Prisma Premium experience! Download the app and log in
            with the same account
          </Message>

          <StoreWrapper>
            <Svg
              id="prisma_checkout-flow_end_btn-store"
              icon={this.md.os() === 'iOS' ? appStore : googlePlay}
              onClick={this.handleAppClick}
            />
          </StoreWrapper>
        </BlockBody>
      </BlockWrapper>
    )
  }
}

const storesToProps = ({ store }: IStoresMap, _nextProps: any) => ({
  flow: store.flow,
  authentication: store.authentication,
})

export default withRouter(connector(storesToProps)(PaymentSucceed))
