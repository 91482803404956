import { CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js';
import styled, { css } from 'src/common/styles'
import { Button2 }  from 'src/common/components/button'
import { ButtonSize, ButtonView } from 'src/common/components/button/types'

// todo разобраться, как прокинуть в страйп тему
export const cardStyles = {
  base: {
    fontFamily: 'CoFo Sans, sans-serif',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: '0.1px',
    color: '#1C1C1E',
    '::placeholder': {
      color: 'rgba(62, 62, 67, 0.3)',
    },
  },
  invalid: {
    color: '#FF5B5B',
  },
}

export const CardElementsWrapper = styled.div`
  margin-top: auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto 1fr;
  gap: 8px;
  grid-template-areas: 
    "cardNumber cardNumber"
    "CardExpiry CardCvc"
    "PayButton PayButton";
`

const CardElementBase = css`
  height: 48px;
  padding: 12px 14px;
  background-color: #F2F2F2;
  border: 1px solid #00000000;
  border-radius: 10px;
  box-shadow: none;
  outline: 0;
  outline-offset: 0;

  transition: all ease-out 100ms;
  
  &.StripeElement--focus {
    background-color: transparent;
    color: ${(props) => props.theme.palette.label.primary};
    border: 1px solid ${(props) => props.theme.palette.highlight.base};
    box-shadow: 0 0 0 2px ${(props) => props.theme.palette.highlight.trail};
  }

  &.StripeElement--invalid {
    background-color: transparent;
    color: ${(props) => props.theme.palette.error.base};
    border: 1px solid ${(props) => props.theme.palette.error.base};

    &.StripeElement--focus {
      border: 1px solid ${(props) => props.theme.palette.error.base};
      color: ${(props) => props.theme.palette.error.base};
      box-shadow: 0px 0px 0px 2px ${(props) => props.theme.palette.error.trail};
    }
  }
`

export const CardNumber = styled(CardNumberElement).attrs({
  fontSize: '50px'
})`
  ${CardElementBase};
  grid-area: cardNumber
`

export const CardExpiry = styled(CardExpiryElement)`
  ${CardElementBase};
  grid-area: CardExpiry
`

export const CardCvc = styled(CardCvcElement)`
  ${CardElementBase};
  grid-area: CardCvc
`

export const PayButton = styled(Button2).attrs({
  view: ButtonView.CTA,
  size: ButtonSize.Large,
})`
  grid-area: PayButton;
`