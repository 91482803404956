import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { CSSTransition } from 'react-transition-group'
import { IStoresMap } from 'src/store/mst'
import connector from 'src/common/decorators/connector'

import { Wrapper, Title, Text } from './styles'

interface IErrorMessageProps {
  title: string,
  text: string,
  type: string,
}
type ErrorMessagePropsType = IErrorMessageProps & RouteComponentProps & ReturnType<typeof storesToProps>

class ErrorMessage extends React.Component<ErrorMessagePropsType> {
  public state = { isShowed: true }

  public onEntered = () => {
    this.props.base.setTimer(() => {
      this.setState({ isShowed: false })
    }, 5000)
  }

  public onExited = () => {
    this.props.base.setError('')
  }

  public componentDidUpdate(prevProps: any) {
    if (prevProps.text !== this.props.text) {
      this.props.base.setTimer(() => {
        this.setState({ isShowed: false })
      }, 5000)
    }
  }

  public render() {
    const { title, text, type } = this.props
    const { isShowed } = this.state

    return (
      <CSSTransition
        appear={true}
        in={isShowed}
        timeout={500}
        unmountOnExit
        onEntered={this.onEntered}
        onExited={this.onExited}
      >
        <Wrapper type={type}>
          <Title>{title}</Title>
          <Text type={type}>{text}</Text>
        </Wrapper>
      </CSSTransition>
    )
  }
}

const storesToProps = ({ store }: IStoresMap, _nextProps: any) => ({
  base: store.base,
  authentication: store.authentication,
})

export default withRouter(connector(storesToProps)(ErrorMessage))