import React from 'react'

import AppleLogin from 'react-apple-login'

import { APPLE_WEB_CLIENT_ID } from 'src/common/constants/authentication'
import config from 'src/common/constants/config'

import { ButtonSize, ButtonView } from 'src/common/components/button'

import { Button } from 'src/common/components/button-google-login/styles'
import appleIcon from 'assets/icons/apple.svg'

export interface IButtonAppleLoginProps {
  id?: string
  className?: string
  redirectUrl?: string
  loading?: boolean
  children?: React.ReactNode
  onClick?: () => void
}

// Service waits info about `platform`, `device_id` and `app` joined by '_' symbol.
const STATE_PLATFORM_DEVICE_ID_APP = 'web__prisma'

// Important: apple won't work with localhost API_URL.
export const ButtonAppleLogin: React.FC<IButtonAppleLoginProps> = ({
  id,
  className,
  redirectUrl,
  loading,
  children,
  onClick,
}: IButtonAppleLoginProps) => (
  <AppleLogin
    clientId={APPLE_WEB_CLIENT_ID}
    redirectURI={config.API_URL + (redirectUrl || '/auth/apple')}
    responseMode="form_post"
    scope="name email"
    responseType="code"
    state={STATE_PLATFORM_DEVICE_ID_APP}
    render={({ onClick: click }) =>
      children ? (
        // @ts-ignore
        React.cloneElement(children, {
          onClick: () => {
            if (onClick) {
              onClick()
            }
            click()
          },
        })
      ) : (
        <Button
          id={id}
          className={className}
          view={ButtonView.Secondary}
          size={ButtonSize.Large}
          startIcon={appleIcon}
          onClick={() => {
            if (onClick) {
              onClick()
            }
            click()
          }}
          isLoading={loading}
          fullWidth
        >
          Sign In with Apple
        </Button>
      )
    }
  />
)

export default ButtonAppleLogin
