import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Header, Title, Wrapper } from '../auth/styles';
import { Content, Subtitle, Price, Button } from './styles';
import history from 'src/router/history';
import { LandingContextConsumer } from 'src/common/decorators/landingContext'
import { sendEvent } from 'src/common/utils/analytics'
import { EVENTS_AMPLITUDE } from 'src/common/constants/analytics/events'
import { AMPLITUDE } from 'src/common/constants/analytics'

type EventsType = keyof typeof EVENTS_AMPLITUDE;

class Offer extends React.Component<RouteComponentProps> {
  public componentDidMount() {
    this.sendAnalytic('ActionShow')
  }
  
  public sendAnalytic(actionType: EventsType) {
    const params = new URLSearchParams(this.props.location.search)

    sendEvent(EVENTS_AMPLITUDE[actionType], [AMPLITUDE], {
      context: 'purchase',
      media_source: params.get('media_source'),
      campaign: params.get('campaign')
    })
  }

  public onClick (landing: string) {
    this.sendAnalytic('ActionButtonTap')
    history.push({
      pathname: `${landing}/payment`,
      search: this.props.location.search
    })
  }

  public render() {
    return (
      <LandingContextConsumer>
        {(landing) => (
          <Wrapper>
            <Content>
              <Header>
                <Title>Start free trial now</Title>
              </Header>
              <Subtitle>Grab your annual subscription <br/>at the best price now!</Subtitle>
              <Price>
                <span>3 days free trial,</span>
                then $29.99/year ($2.49/mo)
              </Price>
              <Button onClick={() => this.onClick(landing)}>
                Continue
              </Button>
            </Content>
          </Wrapper>
        )}
      </LandingContextConsumer>
    )
  }
}

export default withRouter(Offer)