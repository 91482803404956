import styled from 'src/common/styles'

import Svg from 'src/common/components/svg'
import { ComponentSize } from 'src/common/constants/web-kit/sizes'

import { InputProps } from './types'

export const InputContainer = styled.div<{ fullWidth?: boolean }>`
  width: ${(props) => (props.fullWidth ? '100%' : 'inherit')};
`

export const InputWrapper = styled.div`
  position: relative;
`

export const BaseInput = styled.input`
  width: 100%;
  height: 100%;

  padding: 12px 14px;

  font-size: ${(props) => props.theme.typography.bodyRegular.fontSize};
  font-weight: ${(props) => props.theme.typography.bodyRegular.fontWeight};
  line-height: ${(props) => props.theme.typography.bodyRegular.lineHeight};
  letter-spacing: ${(props) =>
    props.theme.typography.bodyRegular.letterSpacing};

  color: ${(props) => props.theme.palette.label.primary};
  background-color: ${(props) => props.theme.palette.fill.tertiary};

  border: 1px solid #00000000;
  border-radius: 10px;

  box-shadow: none;

  outline: 0;
  outline-offset: 0;

  transition: all ease-out 100ms;

  & ~ svg {
    fill: ${(props) => props.theme.palette.label.secondary};
  }

  &:placeholder-shown {
    transition: all ease-out 150ms;

    & ~ svg {
      fill: ${(props) => props.theme.palette.label.tertiary};
    }
  }

  &::placeholder {
    color: ${(props) => props.theme.palette.label.tertiary};
  }

  &:hover,
  &:focus {
    background-color: transparent;
    color: ${(props) => props.theme.palette.label.primary};
    border: 1px solid ${(props) => props.theme.palette.highlight.base};
  }

  &:hover {
    &::placeholder {
      color: ${(props) => props.theme.palette.label.secondary};
    }
  }

  &:focus {
    box-shadow: 0 0 0 2px ${(props) => props.theme.palette.highlight.trail};

    & + svg {
      fill: ${(props) => props.theme.palette.highlight.base};
    }
  }
`

export const StyledInput = styled(BaseInput)<InputProps>`
  ${(props) => {
    switch (props.componentSize) {
      case ComponentSize.Large:
        return `
          height: 48px;
          
          & ~ svg {
            top: 13px;
          }
        `
      case ComponentSize.Medium:
        return `
          height: 40px;
          
          & ~ svg {
            top: 9px;
          }
        `
      case ComponentSize.Small:
        return `
          height: 32px;
          
          & ~ svg {
            top: 6px;
          }
        `
      default:
        return ''
    }
  }}

  /* With Icons */
  ${(props) => props.startIcon && 'padding-left: 44px;'}
  ${(props) => props.endIcon && 'padding-right: 44px;'}

  /* With Error */
  ${(props) =>
    props.error &&
    `
    background-color: transparent;
    color: ${props.theme.palette.error.base};
    border: 1px solid ${props.theme.palette.error.base};
    
    &:placeholder-shown, &:hover, &:focus {
      border: 1px solid ${props.theme.palette.error.base};
    }
    
    &:hover, &:focus {
      color: ${props.theme.palette.error.base};
    }
    
    &:focus {
      box-shadow: 0px 0px 0px 2px ${props.theme.palette.error.trail};
      
      & ~ svg {
        fill: ${props.theme.palette.error.base};
      }
    }
  `}
`

export const ErrorMessage = styled.div<{ visible: boolean }>`
  color: ${(props) => props.theme.palette.error.base};

  min-height: 20px;
  margin-bottom: 6px;

  display: ${(props) => (props.visible ? 'block' : 'none')};

  font-size: ${(props) => props.theme.typography.captionMedium.fontSize};
  font-weight: ${(props) => props.theme.typography.captionMedium.fontWeight};
  line-height: ${(props) => props.theme.typography.captionMedium.lineHeight};
  letter-spacing: ${(props) =>
    props.theme.typography.captionMedium.letterSpacing};

  text-align: center;
`

export const Icon = styled(Svg)`
  position: absolute;
  top: 13px;
`

export const StartIcon = styled(Icon)`
  left: 13px;
`

export const EndIcon = styled(Icon)`
  right: 13px;
`
