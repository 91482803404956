import { observe } from 'mobx'
import { applySnapshot, getRoot, Instance, types } from 'mobx-state-tree'

import { TokenType } from 'src/common/constants/authentication'

import { IStore } from '../index'

import restoreToken from './actions/restore-token'
import sessionUpdate from './actions/session-update'

export interface ISession extends Instance<typeof Session> {}

// @todo think about this - it seems we don't need this model
export const BaseSession = types
  .model('Session', {
    userID: types.maybe(types.string),
    app: types.maybe(types.string),
    token: types.maybe(types.string),
    tokenType: types.maybe(
      types.enumeration('TokenType', [...Object.values(TokenType)])
    ),
  })
  .views((self) => ({
    get rootStore(): IStore {
      return getRoot(self)
    },
  }))
  .views((self) => ({
    get isLoggedIn() {
      return (
        Boolean(self.userID) && Boolean(self.token) && Boolean(self.tokenType)
      )
    },
  }))
  .actions((self) => ({
    reset() {
      applySnapshot(self, {})
    },
  }))

export const Session = BaseSession
  .actions(sessionUpdate)
  .actions(restoreToken)
  .actions((self) => ({
    afterCreate() {
      self.restoreFromCache()
      self.initCache()

      observe(self, 'isLoggedIn', (props: any) => {
        if (!props.newValue) {
          self.clearCache()
        }
      })
    },
  }))
